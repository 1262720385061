import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __makeGetCallDashboardGetRequest,
  __makeGetDashboardGetRequest,
  __makeGetGalleryGetRequest,
  __makeIvrCallPostRequest,
} from "constants/apis"

const Call = props => {
  const [galleryList, setgalleryList] = useState([])

  const [state, setState] = useState({
    audio: "",
    send_to: "",
    more_numbers: "",
    error_message: "",
    success_message: "",
    loading: false,
    audio_name: "",
  })
  const {
    audio,
    send_to,
    more_numbers,
    error_message,
    success_message,
    loading,
    audio_name,
  } = state

  // to update function
  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleGetGallery = () => {
    __makeGetGalleryGetRequest()
      .then(res => {
        if (res.response.response_code === "200") {
          setgalleryList(res?.data?.reverse())
        } else {
          setgalleryList([])
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }
  const [dashboard, setdashboard] = useState({
    total: 0,
    M: 0,
    F: 0,
    new_voters: 0,
    age_18_to_35: 0,
    age_36_to_150: 0,
    dob: 0,
    mobile: 0,
    ps_building: 0,
    tehsil_nm_v: 0,
    cst: 0,
    wardno: 0,
    todayAniversary: 0,
    todayBirthday: 0,
  })

  const __handleGetDashboardGetRequest = () => {
    __makeGetCallDashboardGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setdashboard(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleSubmit = () => {
    if (!audio)
      return updateState({
        error_message: "Please select audio which you want to send !...",
      })

    updateState({
      error_message: "",
      success_message: "",
      loading: true,
    })
    __makeIvrCallPostRequest({
      audio,
      send_to,
      more_numbers: more_numbers
        ? more_numbers.split(",").map(num => num.trim())
        : [],
      vidhansabha: localStorage.getItem("type"),
      audio_name,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({
            success_message: res.response.response_message,
            loading: false,
          })
        } else {
          updateState({
            error_message: res.response.response_message,
            loading: false,
          })
        }
      })
      .catch(error => {
        console.error(error)
        updateState({
          error_message: "technical error - Please try again",
          loading: false,
        })
      })
  }

  useEffect(() => {
    __handleGetGallery()
    __handleGetDashboardGetRequest()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Voice Call")}
            breadcrumbItem={props.t("Schedule")}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col sm={6} md={4}>
                      <div className="form-floating mb-3">
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e => {
                            updateState({
                              audio: e.target.value,
                              audio_name: galleryList.find(
                                item => item.announcement_id == e.target.value
                              ).audio_name,
                            })
                          }}
                          value={audio}
                        >
                          <option value="">Choose Audio</option>
                          {galleryList.map((item, i) => (
                            <option key={i} value={item.announcement_id}>
                              {item.audio_name}
                            </option>
                          ))}
                        </select>
                        <label htmlFor="floatingSelectGrid">Audio</label>
                      </div>
                    </Col>
                    <Col sm={6} md={4}>
                      <div className="form-floating mb-3">
                        <select
                          defaultValue="0"
                          className="form-select"
                          onChange={e => {
                            updateState({ send_to: e.target.value })
                          }}
                          value={send_to}
                        >
                          <option value="">Choose Send to</option>
                          <option value="all">All - {dashboard.total}</option>
                          <option value="new_voters">
                            New Voters - {dashboard.new_voters}
                          </option>
                          <option value="male_voters">
                            Male Voters - {dashboard.M}
                          </option>
                          <option value="female_voters">
                            Female Voters - {dashboard.F}
                          </option>
                          <option value="age_18_35">
                            Voters Age 18 To 35 - {dashboard.age_18_to_35}
                          </option>
                          <option value="above_age_35">
                            Voters Above 35 Age - {dashboard.age_36_to_150}
                          </option>
                          <option value="birthday">
                            Today Birth Day - {dashboard.todayBirthday}
                          </option>
                          <option value="anniversary">
                            Today Anniversary - {dashboard.todayAniversary}
                          </option>
                        </select>
                        <label htmlFor="floatingSelectGrid">Send to</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12} md={8}>
                      <Input
                        type="textarea"
                        id="textarea"
                        onChange={e => {
                          updateState({ more_numbers: e.target.value })
                        }}
                        value={more_numbers}
                        rows="3"
                        style={{
                          height: "250px",
                        }}
                        placeholder={`Note: Add more  mobile number.\nExample :\n\n9898000001,\n9099000002,\n9898000003,...`}
                        invalid={error_message ? true : false}
                        valid={success_message ? true : false}
                      />
                      <FormFeedback type="invalid">
                        {error_message}
                      </FormFeedback>
                      <FormFeedback>{success_message}</FormFeedback>
                    </Col>
                  </Row>

                  <div className="col-md-8 col-sm-12">
                    <Button
                      className="btn btn-bg my-4 float-end"
                      onClick={__handleSubmit}
                    >
                      Make
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Call)
