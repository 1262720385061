import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __generateRandomString } from "constants/constantfun"
import {
  __makeUpdatetermsPrivacyPostRequest,
  __makegetTermAndPolicyGetRequest,
} from "constants/apis"

const Term_Con = props => {
  const [state, setState] = useState({ content: "", success: "", error: "" })

  const { content, success, error } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleUpdateData = () => {
    updateState({ success: "", error: "" })

    __makeUpdatetermsPrivacyPostRequest("privacy", { content })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ success: res.response.response_message })
        } else {
          updateState({ error: res.response.response_message })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleGetData = () => {
    __makegetTermAndPolicyGetRequest("privacy")
      .then(res => {
        console.log(res)
        updateState({ content: res })
      })
      .catch(error => {
        console.error(error)
      })
  }
  useEffect(() => {
    __handleGetData()
    localStorage.getItem("login") == "admin" && localStorage.clear()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Privacy Policy")}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Input
                    type="textarea"
                    multiple
                    style={{ minHeight: "65vh" }}
                    value={content}
                    onChange={e => {
                      updateState({ content: e.target.value })
                    }}
                    invalid={error ? true : false}
                    valid={success ? true : false}
                  />
                  <FormFeedback> {error}</FormFeedback>
                  <FormFeedback valid> {success}</FormFeedback>
                  <Button
                    className="btn btn-bg float-end my-2"
                    onClick={__handleUpdateData}
                  >
                    Save & Update
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Term_Con)
