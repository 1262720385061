import {
  __makeGetVoterDetailsGetRequest,
  __makeUpdayeVoterDetailsPostRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, Col, Input, Row } from "reactstrap"

const AddVoters = ({ voterid, viewonly }) => {
  const [showText, setshowText] = useState(null)
  const [state, setState] = useState({
    status: false,
    vidhansabha: "",
    ward_name: "",
    part_no: "",
    ac_no: "",
    sr_no: "",
    name_hin: "",
    name_eng: "",
    surname: "",
    email_id: "",
    family_hin: "",
    family_eng: "",
    relation_type: "",
    sex: "",
    age: "",
    voter_id: voterid || "",
    house_no: "",
    cast: "",
    mobile: "",
    voter_status: "",
    anubhag: "",
    pollingbooth: "",
    dob: "",
    aniversary_date: "",
    is_voted: "",
    tehsil_hin: "",
    tehsil_eng: "",
    address: "",
    block: "",
  })

  const {
    name_eng,
    surname,
    family_hin,
    family_eng,
    sex,
    age,
    voter_id,
    mobile,
    dob,
    address,
    vidhansabha,
    ward_name,
    pollingbooth,
    tehsil_hin,
  } = state

  const UpdateState = data => setState(prv => ({ ...prv, ...data }))

  const __handleGetVotesData = () => {
    __makeGetVoterDetailsGetRequest(voter_id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          UpdateState({ ...res.data, status: true })
          setshowText("Update Voter's Details")
        } else {
          window.alert(" no user found ")
          setshowText("Add Voter's Details")
        }
      })
      .catch(error => {
        console.error(error)
        setshowText("Add Voter's Details")
      })
  }
  const __handleUpdateVotesData = () => {
    __makeUpdayeVoterDetailsPostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          window.alert("Update Successfully")
        } else {
          window.alert("Failed ")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    voterid && __handleGetVotesData()
  }, [])

  return (
    <React.Fragment>
      <>
        <Row>
          {/* <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={vidhansabha}
                onChange={e => {
                  UpdateState({ vidhansabha: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">School</label>
            </div>
          </Col> */}
          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={name_eng}
                onChange={e => {
                  UpdateState({ name_eng: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Name</label>
            </div>
          </Col>

          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={family_hin}
                onChange={e => {
                  UpdateState({ family_hin: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Father</label>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={family_eng}
                onChange={e => {
                  UpdateState({ family_eng: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Mother</label>
            </div>
          </Col>

          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={sex}
                onChange={e => {
                  UpdateState({ sex: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Gender(M,F)</label>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={ward_name}
                onChange={e => {
                  UpdateState({ ward_name: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Stream</label>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={pollingbooth}
                onChange={e => {
                  UpdateState({ pollingbooth: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">City</label>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={tehsil_hin}
                onChange={e => {
                  UpdateState({ tehsil_hin: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Class</label>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={age}
                onChange={e => {
                  UpdateState({ age: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Age</label>
            </div>
          </Col>

          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={mobile}
                onChange={e => {
                  UpdateState({ mobile: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Mobile</label>
            </div>
          </Col>

          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={dob}
                onChange={e => {
                  UpdateState({ dob: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">DOB(DD/MM/YYYY)</label>
            </div>
          </Col>

          <Col sm={12} md={6} lg={3}>
            <div className="form-floating  mb-3">
              <Input
                type="text"
                className="form-control"
                id="floatingnameInput"
                placeholder="Enter..."
                name="name"
                value={vidhansabha}
                onChange={e => {
                  UpdateState({ vidhansabha: e.target.value })
                }}
              />
              <label htmlFor="floatingnameInput">Address</label>
            </div>
          </Col>
        </Row>
        <div className=" border-2 border-bottom mb-3"></div>
        {!viewonly && (
          <div className="">
            <Button
              onClick={__handleUpdateVotesData}
              className="btn btn-bg bg-gradient float-end"
            >
              Save
            </Button>
          </div>
        )}
      </>
    </React.Fragment>
  )
}

export default AddVoters
