import React from "react"
import { Card, CardBody, Col } from "reactstrap"

//Lightbox
import "react-image-lightbox/style.css"
import { __HOST } from "constants/apis"
import Image from "../../../assets/images/strip.png"

const ListCom = ({ data }) => {
  console.log(data)
  return (
    <React.Fragment>
      <Col lg={4} md={4} sm={5}>
        <Card className="" style={{ cursor: "pointer" }}>
          <CardBody className="border border-2 border-dark p-0">
            <img
              src={
                localStorage.getItem("party_logo")
                  ? "https://digitalchunavparchar.com/uploads" +
                    "/" +
                    localStorage.getItem("party_logo")
                  : Image
              }
              alt=""
              className="w-100"
            />

            <div className="d-flex flex-column gap-1 py-2">
              <div className="d-flex justify-content-between px-2">
                <span>SR no.</span>
                <span>{data?.sr_no}</span>
              </div>
              <div className="d-flex justify-content-between px-2">
                <span>voter name : {data?.name_hin}</span>
                <span>
                  {data?.sex == "M" ? "Male" : "Female"}/ {data?.age}
                </span>
              </div>
              <div className="d-flex justify-content-between px-2">
                <span>Fater/Husband</span>
                <span>{data?.family_hin}</span>
              </div>
              <div className="d-flex justify-content-between px-2">
                <span>Polling Center</span>
                <span className="text-end">{data?.pollingbooth}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center px-2 border-2 border-top border-dark">
              voting time : 10:30 AM to 07:00 PM
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default ListCom
