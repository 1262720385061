import React, { useState } from "react"

import { __formatDate } from "constants/constantfun"
import { __HOST } from "constants/apis"
function calculateAge(birthdate) {
  const today = new Date()
  const birthDate = new Date(birthdate)

  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--
  }

  return age
}
const ListCom = ({ data, index }) => {
  return (
    <React.Fragment>
      <tr>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {index + 1}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.mobile ? "********" + data?.mobile?.slice(8, 10) : "-"}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.tehsil_hin}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {calculateAge(data.dob)}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {__formatDate(data?.dob)}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default ListCom
