import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  Row,
  Input,
  Label,
  FormFeedback,
  Card,
  CardBody,
} from "reactstrap"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import CarouselPage from "./CarouselPage"
import {
  __makeGetPartyListGetRequest,
  __makeGetVidhansabhaListGetRequest,
  __makeLoginPostRequest,
} from "constants/apis"
import { __generateRandomString, __hasAnyValue } from "constants/constantfun"
import { HashLoader } from "react-spinners"
import profile from "assets/images/profile-img.png"
import logo from "./logo-small.png"

const Login = () => {
  let navigate = useNavigate()

  const [dropDown, setDropDown] = useState("")
  const [passwordShow, setPasswordShow] = useState(false)
  const [userdetails, setuser] = useState({
    user_id: "",
    password: "",
    vidhansabha: "",
  })
  const [showotp, setshowotp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [verification, setVerification] = useState({
    token: "",
    authToken: "",
  })

  document.title = "Login | School Near "

  const __handleSubmit = () => {
    let alertdata = { user_id: "", password: "", dropDown: "" }

    if (!userdetails.user_id.trim()) {
      alertdata.user_id = "Fill your username or email id"
    }

    if (!userdetails.password) {
      alertdata.password = "Fill your password"
    }
    setError(prv => ({
      ...prv,
      ...alertdata,
    }))

    if (__hasAnyValue(alertdata)) {
      return
    }
    setLoading(true)

    __makeLoginPostRequest(userdetails)
      .then(res => {
        console.log(res)
        setLoading(false)

        if (res.response.response_code === "200") {
          localStorage.setItem("authUser", res.data.authtoken)
          localStorage.setItem("type", res?.data?.user?.vidhanshabha)
          localStorage.setItem("login", res?.data?.user?.type)
          localStorage.setItem("party_logo", res?.data?.user?.partylogo)
          localStorage.setItem("name", res?.data?.user?.name)

          navigate("/")
        } else if (res.response.response_code === "201") {
          setError(prv => ({
            ...prv,
            user_id: " ",
            password: res.response.response_message,
          }))
        } else {
          setError(prv => ({
            ...prv,
            user_id: " ",
            password: res.response.response_message,
          }))
        }
      })
      .catch(error => {
        setLoading(false)
        setError(prv => ({
          ...prv,
          user_id: " ",
          password: "Some Technical Issue!\n Please try after some time",
        }))
        console.error(error)
      })
  }

  const [partList, setpartList] = useState()

  const __handleGetParty = () => {
    __makeGetVidhansabhaListGetRequest()
      .then(res => {
        if (res.response.response_code === "200") {
          setpartList(res?.data)
        } else {
          setpartList([])
        }
      })
      .catch(error => {
        console.error(error)
        setpartList([])
      })
  }

  useEffect(() => {
    __handleGetParty()
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to School Near.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-4">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        !loading && __handleSubmit()
                        return false
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email address"
                          type="text"
                          value={userdetails.user_id}
                          onChange={e => {
                            setuser(prev => ({
                              ...prev,
                              user_id: e.target.value,
                            }))
                          }}
                          invalid={error.user_id ? true : false}
                        />

                        <FormFeedback type="invalid">
                          {error.user_id}
                        </FormFeedback>
                      </div>

                      <div className="mb-3">
                        {/* <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div> */}
                        <Label className="form-label">Password</Label>
                        {/* <div className="input-group auth-pass-inputgroup"> */}
                        <Input
                          name="password"
                          type={passwordShow ? "text" : "password"}
                          placeholder="Enter Password"
                          value={userdetails.password}
                          onChange={e => {
                            setuser(prev => ({
                              ...prev,
                              password: e.target.value,
                            }))
                          }}
                          invalid={error.password ? true : false}
                        />

                        <FormFeedback type="invalid">
                          {error.password}
                        </FormFeedback>

                        {/* <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button> */}
                        {/* </div> */}
                      </div>

                      <div className="form-check">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="auth-remember-check"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="auth-remember-check"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block d-flex justify-content-center"
                          type="submit"
                        >
                          {loading ? (
                            <HashLoader color="#fff" size={20} />
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} All rights reserved by RI Websoft
                  Pvt. Ltd..
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )

  function loader(params) {
    return (
      <div className="position-absolute top-0 bottom-0 right-0 left-0">
        loading
      </div>
    )
  }
}

export default Login
