import { __makeGetDashboardGetRequest } from "constants/apis"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, CardText, Col } from "reactstrap"

const todaydate = new Date()
const Summery = () => {
  const [isLoading, setisLoading] = useState(false)
  const [dashboard, setdashboard] = useState({
    total: 0,
    M: 0,
    F: 0,
    new_voters: 0,
    age_18_to_35: 0,
    age_36_to_150: 0,
    dob: 0,
    mobile: 0,
    ps_building: 0,
    tehsil_nm_v: 0,
    cst: 0,
    wardno: 0,
    ages: [],
  })

  const __handleGetDashboardGetRequest = () => {
    setisLoading(true)
    __makeGetDashboardGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setdashboard(res?.data)
        }
        setisLoading(false)
      })
      .catch(error => {
        console.error(error)
        setisLoading(false)
      })
  }

  useEffect(() => {
    __handleGetDashboardGetRequest()
  }, [])

  return (
    <>
      <Col md={2}>
        <Link to="/student-ist">
          <Card className="bg-primary shadow-sm  bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.total || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Students</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>

      <Col md={2}>
        <Link to="/student-ist?s=M">
          <Card className="bg-warning shadow-sm  bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">{dashboard?.M || 0}</span>
              )}
              <CardText className="mt-3 text-white">
                Total Male Students
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/student-ist?s=F">
          <Card className="bg-danger  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">{dashboard?.F || 0}</span>
              )}
              <CardText className="mt-3 text-white">
                Total Female Students
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/student-ist?max=10&min=3">
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.ages?.reduce((accumulator, currentValue) => {
                    if (currentValue < 11) {
                      accumulator++
                    }
                    return accumulator
                  }, 0) || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">
                Students 3 to 10 Age
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to="/student-ist?max=150&min=11">
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.ages?.reduce((accumulator, currentValue) => {
                    if (currentValue > 10) {
                      accumulator++
                    }
                    return accumulator
                  }, 0) || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">
                Students Above 10 Age
              </CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to={`/counting`}>
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.wardno || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Stream</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to={`/location`}>
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.ps_building || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Total Location</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to={`/counting-tehsil`}>
          <Card className="bg-dark  shadow-sm bg-gradient">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.tehsil_nm_v || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Class</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>

      <Col md={2}>
        <Link
          to={`/student-ist?todaybirth=${todaydate.getDate()}/${
            todaydate.getMonth() + 1
          }`}
        >
          <Card className="bg-primary  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.todayBirthday || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Today BirthDay</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to={`/student-ist?category=1`}>
          <Card className="bg-danger  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.category_1 || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Category 1</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to={`/student-ist?category=2`}>
          <Card className="bg-success  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.category_2 || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Category 2</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
      <Col md={2}>
        <Link to={`/student-ist?category=3`}>
          <Card className="bg-warning  shadow-sm bg-gradient ">
            <CardBody className="text-center">
              {isLoading ? (
                <i className="bx bx-loader bx-spin text-white align-middle h2 "></i>
              ) : (
                <span className="h1 text-white fs-1">
                  {dashboard?.category_3 || 0}
                </span>
              )}
              <CardText className="mt-3 text-white">Category 3</CardText>
            </CardBody>
          </Card>
        </Link>
      </Col>
    </>
  )
}

export default Summery
