import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __makeGetGalleryGetRequest,
  __makeGetListPostRequest,
} from "constants/apis"
import Filter from "./comp/Filter"
import List from "./comp/List"
const Search = props => {
  const [listdata, setgalleryList] = useState([])

  const __handleGetGallery = filter => {
    setgalleryList(null)

    __makeGetListPostRequest(filter)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setgalleryList(res?.data?.reverse())
        } else {
          setgalleryList([])
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Search")}
          />
          <Filter __handleGetGallery={__handleGetGallery} />
          <List companionList={listdata} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Search)
