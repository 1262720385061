import {
  __makeGetListPostRequest,
  __makeGetPartNoListGetRequest,
  __makeGetTehsilListGetRequest,
} from "constants/apis"
import React, { useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"

const Filter = ({ __handleGetWork }) => {
  const urlParams = new URLSearchParams(window.location.search)

  const [field, setfield] = useState({
    sr_no: "",
    area_name: "",
    area: "",
    nagar: "",
    mdh: "",
    approved_year: "",
  })

  const { sr_no, area_name, area, nagar, mdh, approved_year } = field

  const updateFilter = data => setfield(prv => ({ ...prv, ...data }))

  const __handleSubmit = () => {
    let filterArray = []
    for (const key in field) {
      if (field.hasOwnProperty(key) && field[key] !== "") {
        console.log(key)
        console.log(field[key])
        filterArray.push({ [key]: { $regex: field[key], $options: "i" } })
      }
    }
    console.log(filterArray)
    __handleGetWork({
      filter: {
        $and: filterArray,
      },
    })
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col sm={4} md={2} lg={1}>
                <div className="form-floating  mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={sr_no}
                    onChange={e => {
                      updateFilter({ sr_no: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">S NO.</label>
                </div>
              </Col>

              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  {/* <select
                    defaultValue="0"
                    value={type}
                    className="form-select"
                    onChange={e => {
                      updateFilter({ type: e.target.value })
                    }}
                  >
                    <option value="">Choose ....</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Type of Work</label> */}
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={area_name}
                    onChange={e => {
                      updateFilter({ area_name: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">Area's name</label>
                </div>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  {/* <select
                    defaultValue="0"
                    className="form-select"
                    value={area}
                    onChange={e => {
                      updateFilter({ area: e.target.value })
                    }}
                  >
                    <option value="">Choose ....</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Village/Ward</label> */}
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={area}
                    onChange={e => {
                      updateFilter({ area: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">Village/Ward</label>
                </div>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  {/* <select
                    defaultValue="0"
                    className="form-select"
                    value={nagar}
                    onChange={e => {
                      updateFilter({ nagar: e.target.value })
                    }}
                  >
                    <option value="">Choose ....</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">
                    Village Council/Nagar
                  </label> */}
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={nagar}
                    onChange={e => {
                      updateFilter({ nagar: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">
                    Village Council/Nagar
                  </label>
                </div>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={approved_year}
                    onChange={e => {
                      updateFilter({ approved_year: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">Approved Year</label>
                </div>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={mdh}
                    onChange={e => {
                      updateFilter({ mdh: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">mdh</label>
                </div>
              </Col>

              <Col sm={4} md={3}>
                <Button className="btn btn-bg " onClick={__handleSubmit}>
                  Search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Filter
