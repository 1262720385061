import { __makeUploadGalleryFilePostRequest } from "constants/apis"
import { __hasAnyValue } from "constants/constantfun"
import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  Progress,
  Row,
} from "reactstrap"

const AddAudio = ({ galleryList, setgalleryList }) => {
  const [audioName, setaudioName] = React.useState("")
  const [audio, setaudio] = React.useState("")
  const [error, setError] = useState({ audio_name: "", audio: "" })
  const [upload, setUpload] = useState("")
  const [progress, setProgress] = useState(null)

  const [modal1, setModal1] = React.useState(false)
  const toggle1 = () => {
    setModal1(!modal1)
    setaudio("")
    setaudioName("")
    setProgress(null)
    setError({})
    setUpload("")
  }

  const __handleSubmit = async () => {
    let alertdata = { audio: "" }

    if (!audio) {
      alertdata.audio = "Select audio"
    }
    setError(prv => ({
      ...prv,
      ...alertdata,
    }))

    if (__hasAnyValue(alertdata)) {
      return
    }
    setProgress(10)
    setUpload("")
    const formdata = new FormData()
    formdata.append("image_one", audio)
    setTimeout(() => {
      setProgress(30)
    }, 50)

    __makeUploadGalleryFilePostRequest(formdata)
      .then(res => {
        setProgress(80)
        console.log(res)

        if (res.response.response_code === "200") {
          setUpload("Successfully uploaded")
          setgalleryList([res.data, ...galleryList])
        } else {
          setError(prv => ({
            ...prv,
            audio_name: " ",
            audio: res.response.response_message,
          }))
        }
        setProgress(100)
      })
      .catch(error => {
        console.error(error)
        setProgress(100)
      })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={modal1}
        fade={true}
        toggle={toggle1}
        className=" mx-auto"
        centered
      >
        <ModalBody className="">
          <Card className="btn-bg">
            <CardBody
              style={{ fontWeight: "600" }}
              className="text-center font-size-14"
            >
              Upload Image
            </CardBody>
          </Card>
          {/* <div className="form-floating  mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={audioName}
              onChange={e => {
                setaudioName(e.target.value)
                setError(prv => ({
                  ...prv,
                  audio_name: "",
                }))
              }}
              invalid={error.audio_name ? true : false}
              valid={upload ? true : false}
            />
            <FormFeedback>{error.audio_name}</FormFeedback>
            <label htmlFor="floatingnameInput">audio Name</label>
          </div> */}
          <div className="mb-3">
            <Input
              type="file"
              className="form-control"
              // accept="audio/mp3"
              onChange={e => {
                // 1727672

                // if (e.target.files[0].size > 1000000) {
                //   return setError(prv => ({
                //     ...prv,
                //     audio:
                //       "The selected audio exceeds the maximum allowed file size (1MB). Please choose a smaller audio.",
                //   }))
                // }
                setError(prv => ({
                  ...prv,
                  audio: "",
                }))
                setUpload("")
                setProgress(null)
                setaudio(e.target.files[0])
                console.log(e.target.files[0])
              }}
              invalid={error.audio ? true : false}
              valid={upload ? true : false}
            />
            {progress && (
              <Progress
                className="my-3"
                style={{
                  height: "3px",
                }}
                value={progress}
                color={error?.audio ? "danger" : "success"}
              />
            )}
            <FormFeedback>{error.audio}</FormFeedback>
            <FormFeedback valid={true}>{upload}</FormFeedback>
          </div>
          {audio && (
            <div className="text-center">
              <CardImg
                className="img-fluid"
                src={URL.createObjectURL(audio)}
                alt=""
                style={{ maxHeight: "300px", maxWidth: "100%", width: "auto" }}
              />
            </div>
          )}
          <div className="d-flex justify-content-end gap-2 mt-4">
            {!upload && (
              <Button
                onClick={toggle1}
                className="btn btn-warning mx-2 bg-gradient py-1"
              >
                Cancel
              </Button>
            )}
            {!upload && (
              <Button
                onClick={__handleSubmit}
                className="btn  blue-bg mx-2 bg-gradient py-1"
              >
                Save
              </Button>
            )}
            {upload && (
              <Button
                onClick={toggle1}
                className="btn btn-success mx-2 bg-gradient py-1"
              >
                Close
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
      <Row>
        <Col>
          <Button
            onClick={() => {
              toggle1()
            }}
            className="btn btn-bg bg-gradient float-end mt-2 d-flex  gap-1 justify-content-center align-items-center mb-3"
          >
            <i className="bx bx-plus"></i> Add Image
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AddAudio
