import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import {
  __formatDate,
  __formatDate2,
  __generateRandomString,
} from "constants/constantfun"
import { withTranslation } from "react-i18next"
import { __getApiData, __postApiData } from "constants/apis"
import { HashLoader } from "react-spinners"

const WebHookReport = props => {
  const [history, sethistory] = useState([])

  const [loading, setloading] = useState(false)

  const [paginantion, setpaginantion] = useState({ limit: 50, offset: 50 })
  const { limit, offset } = paginantion
  const [count, setCount] = useState(null)
  const updatePage = data => setpaginantion(prv => ({ ...prv, ...data }))

  const [state, setState] = useState({
    from_date: __formatDate2(new Date().valueOf() - 3600000),
    to_date: __formatDate2(new Date()),
    number: "",
  })
  const UpdateState = data => setState(prv => ({ ...prv, ...data }))

  const __handleGetHistory = skip => {
    setloading(true)
    sethistory([])

    __postApiData(`/api/whatsapp/get_webhoook_whatsapp/${skip / 50}`, {
      ...state,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          console.log(res.data.records)
          sethistory(res.data.records || [])
          setCount(res.data.counts.total)
        }
        setloading(false)
      })
      .catch(error => {
        console.error(error)
        setloading(false)
      })
  }

  useEffect(() => {
    __handleGetHistory(50)
  }, [])

  useEffect(() => {
    console.log(state.from_date)
  }, [state])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("WhatsApp")}
            breadcrumbItem={props.t("Messages History")}
          />

          <Card>
            <CardBody>
              <FilterBox
                handleSubmit={() => __handleGetHistory(50)}
                state={state}
                updateState={UpdateState}
              />
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Transaction ID</th>
                          <th className="text-center">Mobile</th>
                          <th className="text-center">WABA Number</th>
                          <th className="text-center">Conversation</th>
                          <th className="text-center">Type</th>
                          <th className="text-center">Total Charges</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Cause</th>
                          <th className="text-center">Send Time</th>
                          <th className="text-center">Delivered Time</th>
                          <th className="text-center">Read Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {history.map((item, i) => (
                          <tr className="" key={i + 1}>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {i + 1}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.uuId + " - " + item.msgId || "-"}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.mobileNo
                                ? "********" +
                                  String(item.mobileNo)?.slice(10, 12)
                                : "-"}{" "}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.wabaNumber}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.billingModel}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.msgType}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.charges}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.status == "error" ? (
                                <div className="d-flex  flex-column align-items-center">
                                  <span className="font-size-11 badge-soft-danger badge bg-secondary">
                                    {item.status}
                                  </span>
                                  {/* <span className="font-size-11 badge-soft-danger badge bg-secondary mt-1">
                                    {item.reason}
                                  </span> */}
                                </div>
                              ) : (
                                <>{item.status}</>
                              )}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.cause}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item?.submitTime &&
                                __formatDate(Number(item?.submitTime))}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item?.deliveryTime &&
                                __formatDate(Number(item?.deliveryTime))}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item?.readTime != "0" && item?.readTime
                                ? __formatDate(Number(item?.readTime))
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  {loading && (
                    <div className="d-flex justify-content-center my-5 py-3">
                      <HashLoader color="#36d7b7" />
                    </div>
                  )}
                </Fragment>
              </Row>

              {count == 0 && <div className="text-center">No Data Found</div>}
              <Row>
                <Col className="my-3 justify-content-end d-flex">
                  <Button
                    className="btn btn-primary mx-2 px-4"
                    onClick={() => {
                      console.log(offset > 0)
                      offset > 50 && __handleGetHistory(offset - limit)
                      offset > 50 && updatePage({ offset: offset - limit })
                    }}
                    disabled={offset > 50 ? false : true}
                  >
                    Prv
                  </Button>
                  <Input value={count || 0} style={{ width: "100px" }} disabled>
                    {" "}
                  </Input>
                  <Button
                    className="btn btn-primary mx-2 px-4"
                    onClick={() => {
                      __handleGetHistory(offset + limit)
                      updatePage({ offset: offset + limit })
                    }}
                    disabled={offset > count ? true : false}
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(WebHookReport)

const FilterBox = ({ state, updateState, handleSubmit }) => {
  const { from_date, to_date, number } = state
  return (
    <Row className="mb-2 ">
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">From Date</Label>
          <Input
            name="name"
            type="datetime-local"
            value={from_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ from_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">To Date</Label>
          <Input
            min={from_date}
            name="name"
            type="datetime-local"
            value={to_date}
            placeholder="Name... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ to_date: e.target.value })
            }}
          />
        </div>
      </Col>
      <Col md={"3"} className="">
        <div className="mb-3">
          <Label className="form-label">Mobile Number</Label>
          <Input
            name="name"
            type="text"
            value={number}
            placeholder="Number... "
            className=" border-0 bg-light rounded-0 p-3"
            onChange={e => {
              updateState({ number: e.target.value })
            }}
          />
        </div>
      </Col>

      <Col sm="12">
        <div className="float-end mb-4 mt-1">
          <Button
            className="me-2 btn bg-secondary  bg-gradient"
            onClick={() => {
              updateState({
                from_date: "",
                to_date: "",
                number: "",
              })
            }}
          >
            Clear
          </Button>
          <Button
            onClick={handleSubmit}
            className=" btn bg-success  border-success bg-gradient"
          >
            Search
          </Button>
        </div>
      </Col>
    </Row>
  )
}
