import axios from "axios"
import React from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

//Lightbox
import "react-image-lightbox/style.css"
import { __HOST } from "constants/apis"

const ListCom = ({ cardData }) => {
  return (
    <React.Fragment>
      <Col lg={3} md={4} sm={5}>
        <Row>
          <div className="d-flex w-100 gap-2">
            <Card className=" flex-grow-1" style={{ cursor: "pointer" }}>
              text
            </Card>
            <Card
              className="flex-grow-0"
              style={{ cursor: "pointer", background: "rgba(0,0,0,0)" }}
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
                width={40}
                height={40}
                alt=""
              />
            </Card>
          </div>
        </Row>
      </Col>
    </React.Fragment>
  )
}

export default ListCom
