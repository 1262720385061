import {
  __makeGetCastNoListGetRequest,
  __makeGetListPostRequest,
  __makeGetPartNoListGetRequest,
  __makeGetTehsilListGetRequest,
  __makeGetWardListGetRequest,
  __makeGetWardNoListGetRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
const anotherField = {
  name_eng: "name_hin",
  family_eng: "family_hin",
  tehsil_eng: "tehsil_hin",
}
const Filter = ({ __handleGetGallery }) => {
  const urlParams = new URLSearchParams(window.location.search)

  const [field, setfield] = useState({
    sr_no: "",
    family_eng: "",
    name_eng: "",
    voter_id: "",
    part_no: "",
    tehsil_eng: urlParams.get("tehsil_nm_en")
      ? urlParams.get("tehsil_nm_en")
      : "",
    section_nm_v: urlParams.get("section_nm_v")
      ? urlParams.get("section_nm_v")
      : "",
    age_min: "",
    age_max: "",
    mobile: "",
    sex: "",
    vidhansabha: localStorage.getItem("type"),
    ward_name: "",
    cast: "",
  })

  const {
    sr_no,
    name_eng,
    family_eng,
    voter_id,
    part_no,
    tehsil_eng,
    age_min,
    age_max,
    mobile,
    sex,
    section_nm_v,
    ward_name,
    cast,
  } = field

  const updateFilter = data => setfield(prv => ({ ...prv, ...data }))

  const __handleSubmit = () => {
    let filterArray = []
    for (const key in field) {
      if (field.hasOwnProperty(key) && field[key] !== "") {
        console.log(key)
        console.log(field[key])

        if (anotherField[key]) {
          filterArray.push({
            $or: [
              { [key]: { $regex: field[key], $options: "i" } },
              { [anotherField[key]]: { $regex: field[key], $options: "i" } },
            ],
          })
        } else if (key == "age_min" || key == "age_max") {
          filterArray.push({
            age: {
              $lt: age_max,
              $gt: age_min,
            },
          })
        } else {
          console.log("else")
          filterArray.push({ [key]: { $regex: field[key], $options: "i" } })
        }
      }
    }
    console.log(filterArray)
    __handleGetGallery({
      filter: {
        $and: filterArray,
      },
      search: true,
    })
  }

  const [tehsilList, setTehsil] = useState([])
  const [partList, setpart] = useState([])
  const [wardList, setward] = useState([])
  const [castList, setCast] = useState([])

  const __handlelist = () => {
    __makeGetTehsilListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setTehsil(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }
  const __handlelist1 = () => {
    __makeGetPartNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setpart(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handlelist2 = () => {
    __makeGetWardNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setward(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handlelist3 = () => {
    __makeGetCastNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setCast(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handlelist()
    __handlelist1()
    __handlelist2()
    __handlelist3()
  }, [])
  useEffect(() => {
    console.log(urlParams)
    if (urlParams.get("tehsil_nm_en") && tehsil_eng) {
      __handleSubmit()
    } else if (urlParams.get("section_nm_v") && section_nm_v) {
      __handleSubmit()
    }
  }, [tehsil_eng, section_nm_v])

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ part_no: e.target.value })
                    }}
                  >
                    <option value="">Choose Part No.</option>

                    {partList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Part no</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ tehsil_eng: e.target.value })
                    }}
                    value={tehsil_eng}
                  >
                    <option value="">Choose Tehsil</option>

                    {tehsilList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Tehsil</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ ward_name: e.target.value })
                    }}
                    value={ward_name}
                  >
                    <option value="">Choose Ward</option>

                    {wardList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Ward</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ cast: e.target.value })
                    }}
                    value={cast}
                  >
                    <option value="">Choose Cast</option>

                    {castList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Cast</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ sex: e.target.value })
                    }}
                    value={sex}
                  >
                    <option value="">Choose Gender</option>
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Works with selects</label>
                </div>
              </Col>

              <Col sm={4} md={3}>
                <Button className="btn btn-bg " onClick={__handleSubmit}>
                  Search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Filter
