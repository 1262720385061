import React, { useState } from "react"

import { __formatDate } from "constants/constantfun"
import { __HOST } from "constants/apis"
import { Modal, ModalHeader } from "reactstrap"
import AddVoters from "./AddVoters"
function calculateAge(birthdate) {
  const today = new Date()
  const birthDate = new Date(birthdate)

  let age = today.getFullYear() - birthDate.getFullYear()
  const monthDiff = today.getMonth() - birthDate.getMonth()

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--
  }

  return age
}
const ListCom = ({ data, index }) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [modal2, setModal2] = useState(false)
  const toggle2 = () => setModal2(!modal2)

  return (
    <React.Fragment>
      {/* <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}> Update Voter's Data</ModalHeader>
        <div className="p-3">
          <AddVoters voterid={data?._id} />
        </div>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} size="xl">
        <ModalHeader toggle={toggle2}>Voter's Details</ModalHeader>
        <div className="p-3">
          <AddVoters voterid={data?._id} viewonly />
        </div>
      </Modal> */}

      <tr>
        {/* <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          <i className="bx bx-square-rounded"></i>
          <i className="bx bx-check-square"></i>
        </td> */}
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {index + 1}
        </td>
        {/* <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.name_eng?.slice(0, 3) + "*******"}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.family_hin?.slice(0, 3) + "*******"}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.family_eng?.slice(0, 3) + "*******"}
        </td> */}
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.mobile ? "********" + data?.mobile?.slice(8, 10) : "-"}{" "}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data?.tehsil_hin}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {calculateAge(data.dob)}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {__formatDate(data?.dob)}
        </td>
        {/* <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <a
                className="btn btn-sm btn-soft-primary"
                id="viewtooltip-10"
                href="#"
                onClick={toggle2}
              >
                <i className="mdi mdi-eye-outline"></i>
              </a>
            </li>
            <li>
              <a
                className="btn btn-sm btn-soft-info"
                id="edittooltip-10"
                href="#"
                onClick={() => setModal(true)}
              >
                <i className="mdi mdi-pencil-outline"></i>
              </a>
            </li>
        
          </ul>
        </td> */}
      </tr>
    </React.Fragment>
  )
}

export default ListCom
