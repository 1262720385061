import React, { Fragment, useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import { Col, Row, Card, CardBody, Table, CardImg, Button } from "reactstrap"
import ListCom from "./ListCom"
import { __generateRandomString } from "constants/constantfun"
import noImage from "../../../assets/images/no_image.png"

const List = ({ companionList }) => {
  const [paginantion, setpaginantion] = useState({ limit: 100, offset: 0 })
  const { limit, offset } = paginantion

  const updatePage = data => setpaginantion(prv => ({ ...prv, ...data }))

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          {companionList ? (
            companionList.length > 0 ? (
              <Card>
                <CardBody>
                  <Fragment>
                    <Row>
                      {companionList
                        .slice(offset, limit + offset)
                        .map((data, i) => (
                          <ListCom
                            data={data}
                            index={i}
                            key={__generateRandomString(10)}
                          />
                        ))}
                    </Row>
                  </Fragment>
                </CardBody>
              </Card>
            ) : (
              <>{NoDataFound()}</>
            )
          ) : (
            <>{Loader()}</>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )

  function NoDataFound() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <CardImg
            className="img-fluid"
            src={noImage}
            alt=""
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              width: "auto",
            }}
          />
        </Row>
      </>
    )
  }
  function Loader() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <HashLoader color="#36d7b7" />
        </Row>
      </>
    )
  }
}

export default List
