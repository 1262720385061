import React from "react"

const ListCom = ({ data, index }) => {
  console.log(data)
  return (
    <React.Fragment>
      <tr className="bg-danger">
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {index + 1}
        </td>
        <td className={` ${index % 2 == 1 && "bg-light"} `}>{data.name_hin}</td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data.voter_id}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data.family_hin}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data.sex}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data.age}
        </td>
        <td className={` text-center ${index % 2 == 1 && "bg-light"} `}>
          {data.mobile}
        </td>
      </tr>
    </React.Fragment>
  )
}

export default ListCom
