import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  CardTitle,
  Progress,
} from "reactstrap"

//redux
import withRouter from "components/Common/withRouter"

import { useParams } from "react-router-dom"
import {
  __HOST,
  __makeGetPoleGetRequest,
  __makeUpdatePolePutRequest,
} from "constants/apis"

const Pole = () => {
  document.title = "Poll | School Near "

  const { id } = useParams()

  const [state, setstate] = useState({})
  const [showResult, setResult] = useState({ type: "null", message: "" })
  const [check, setCheck] = useState("")

  const __handleUpdate = option_id => {
    __makeUpdatePolePutRequest(id, option_id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setstate(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetPoll = () => {
    __makeGetPoleGetRequest(id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setstate(res?.data)
          setResult(prv => ({ ...prv, type: "poll" }))
        } else if (res.response.response_code === "201") {
          setResult(prv => ({ ...prv, type: "stoped" }))
        } else {
          setResult(prv => ({ ...prv, type: "404" }))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  useEffect(() => {
    __handleGetPoll()
  }, [])

  return (
    <React.Fragment>
      <div
        className="page-content bg-primary"
        style={{ height: "100%", minHeight: "100vh" }}
      >
        <Container fluid>
          <Row className="justify-content-center align-items-center">
            <Col sm={12} md={10} lg={6}>
              <Card>
                <CardBody className="p-5">
                  {showResult.type == "poll" && (
                    <>
                      <div className="h2 mb-4">{state.message}</div>
                      {state?.options?.map((item, index) => (
                        <div className="my-3" key={index}>
                          <div
                            className=" d-flex gap-2"
                            onClick={() => {
                              if (localStorage.getItem(id) == id) return
                              localStorage.setItem(item._id, item._id)
                              localStorage.setItem(id, id)
                              setCheck(item._id)
                              __handleUpdate(item._id)
                            }}
                          >
                            <div
                              className={`border border-3 p-4 flex-grow-1 d-flex align-items-center ${
                                (localStorage.getItem(item._id) ||
                                  check == item._id) &&
                                "border-primary"
                              } `}
                            >
                              {localStorage.getItem(item._id) ||
                              check == item._id ? (
                                <span className="bx bx-checkbox-checked fs-2 me-3 text-primary" />
                              ) : (
                                <span className="bx bx-checkbox fs-2 me-3" />
                              )}
                              <span className="h5 my-auto">{item.name}</span>
                            </div>
                            {item.path && (
                              <img
                                src={__HOST() + "/" + item.path}
                                alt="img"
                                width={80}
                                className="float-end"
                              />
                            )}
                          </div>
                          {localStorage.getItem(id) && (
                            <div className=" mb-5 mt-1">
                              <div className="text-center">
                                {(
                                  (item.count / state.total_votes) *
                                  100
                                ).toFixed(1)}
                                %
                              </div>
                              <Progress
                                style={{
                                  height: "10px",
                                }}
                                color={
                                  localStorage.getItem(item._id) ||
                                  check == item._id
                                    ? "primary"
                                    : "secondary"
                                }
                                value={(item.count / state.total_votes) * 100}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                      {state?.total_votes && localStorage.getItem(id) && (
                        <div className="text-end h5">
                          Total Votes : {state?.total_votes}
                        </div>
                      )}
                    </>
                  )}
                  {showResult.type == "stoped" && (
                    <>
                      <CardTitle> Poll stoped</CardTitle>
                    </>
                  )}
                  {showResult.type == "404" && (
                    <>
                      <CardTitle>404: Poll Not Found</CardTitle>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Pole)
