import React from "react"
import { Card, CardTitle, Col, Row } from "reactstrap"

import "react-image-lightbox/style.css"
import { __HOST } from "constants/apis"
import { Link } from "react-router-dom"

const Cards = ({ cardData }) => {
  return (
    <React.Fragment>
      <Col lg={4} md={6} sm={6} className=" col-12">
        <Link to={cardData.path}>
          <Card className="shadow-sm " style={{ cursor: "pointer" }}>
            <Row className="no-gutters align-items-center m-2">
              <Col
                sm={12}
                className=" rounded p-1 d-flex justify-content-center align-items-center border-bottom rounded-0 "
                style={{ height: "150px" }}
              >
                <img className="img-fluid" src={cardData?.img} alt="Image" />
              </Col>
              <div className="d-flex align-items-center mt-2 mx-0 px-0 justify-content-center">
                <CardTitle className=" my-auto mx-2">
                  {cardData?.name}
                </CardTitle>
              </div>
            </Row>
          </Card>
        </Link>
      </Col>
    </React.Fragment>
  )
}

export default Cards
