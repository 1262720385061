import React, { Fragment, useEffect, useState } from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __makeCreatePostRequest,
  __makeCreateWorkPostRequest,
  __makeGetVidhansabhaListGetRequest,
  __makeGetWorkPostRequest,
  __makeSignupPostRequest,
  __makeUpdateMobileUserStatusPutRequest,
  __makegetUsersGetRequest,
  __makegetteamUsersGetRequest,
} from "constants/apis"
import AddBox from "./comp/AddBox"

const SMStemplate = props => {
  //meta title
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [state, setState] = useState({
    name: "",
    phone: "",
    password: "",
  })

  const { name, phone, password } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [list, setlist] = useState(null)

  //   const __handleGetWork = filter => {
  // __makegetteamUsersGetRequest()
  //   .then(res => {
  //     console.log(res)
  //     if (res.response.response_code === "200") {
  //       setlist(res?.data.reverse())
  //     } else {
  //       setlist([])
  //     }
  //   })
  //   .catch(error => {
  //     console.error(error)
  //   })
  //   }

  useEffect(() => {
    localStorage.getItem("login") == "admin" && localStorage.clear()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Add SMS Templates")}
          />
          <AddBox />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(SMStemplate)
