import React, { useEffect, useState } from "react"
import { Card, CardBody, CardText, Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __makeGetGalleryGetRequest,
  __makeGetListCountSortPostRequest,
  __makeGetListCountSortTehsilPostRequest,
  __makeGetListPostRequest,
} from "constants/apis"
import List from "./comp/List"
const CountingAreaBies = props => {
  const [listdata, setgalleryList] = useState([])

  const __handleGetGallery = () => {
    setgalleryList(null)

    __makeGetListCountSortTehsilPostRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          console.log(res?.data)
          let newList = res?.data.sort((a, b) => {
            return parseInt(a._id) - parseInt(b._id)
          })
          setgalleryList(newList)
        } else {
          setgalleryList()
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }

  useEffect(() => {
    __handleGetGallery()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Class Bies")}
          />
          <List companionList={listdata} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CountingAreaBies)
