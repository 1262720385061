import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __makeGetGalleryGetRequest,
  __makeGetListPostRequest,
} from "constants/apis"
import List from "./comp/List"
const PdfGenrator = props => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("PDF Generator")}
          />
          {/* <Filter __handleGetGallery={__handleGetGallery} /> */}

          <List companionList={[]} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(PdfGenrator)
