import React, { Fragment } from "react"
import { Button, Card, CardBody, Container, Input, Table } from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { withTranslation } from "react-i18next"
import { __generateRandomString } from "constants/constantfun"
import * as XLSX from "xlsx"
import {
  __makeGetListPostRequest,
  __makeImportVotersPostRequest,
} from "constants/apis"
const FieldArray = [
  "student_name",
  "father_name",
  "mother_name",
  "sex",
  "stream",
  "city",
  "class",
  "age",
  "mobile",
  "dob",
  "address",
  "category",
]

const ExcelImport = props => {
  const [excelData, setExcelData] = React.useState([])

  const handleFileUpload = e => {
    const file = e.target.files[0]
    if (!file) return
    const reader = new FileReader()

    reader.onload = event => {
      const binaryString = event.target.result
      const workbook = XLSX.read(binaryString, { type: "binary" })

      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const data = XLSX.utils.sheet_to_json(sheet)

      setExcelData(data)
    }

    reader.readAsBinaryString(file)
  }

  const __handleSubmit = () => {
    console.log(excelData)
    const newList = excelData.map(item => ({
      vidhansabha: item.city,
      name_eng: item.student_name,
      ward_name: item.stream,
      pollingbooth: item.address,
      tehsil_hin: item.class,
      family_hin: item.father_name,
      family_eng: item.mother_name,
      sex: item.sex,
      age: item.age,
      mobile: item.mobile,
      dob: item.dob,
      category: item.category,
    }))
    __makeImportVotersPostRequest({ list: newList })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          window.alert("Uploaded file successfully")
        } else {
          window.alert("Failed to upload file")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Excel Import")}
          />
          <Card>
            <CardBody className="d-flex justify-content-between align-items-center ">
              <div className="d-flex flex-column ">
                <Input
                  type="file"
                  className="form-control"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />

                <a href="/sample.xlsx" className="text-nowrap my-2" download>
                  Download Sample Excel
                </a>
              </div>
              <Button
                className="btn btn-bg"
                onClick={__handleSubmit}
                disabled={excelData.length > 0 ? false : true}
              >
                Upload
              </Button>
            </CardBody>
          </Card>
          {excelData.length > 0 && (
            <Card>
              <CardBody>
                <Fragment>
                  <div className="table-responsive react-table ">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          {excelData.length > 0 &&
                            Object.keys(excelData[0]).map((key, index) => (
                              <th
                                key={index}
                                className={`text-center  ${
                                  FieldArray.includes(key)
                                    ? ""
                                    : "border border-2 border-danger "
                                }`}
                              >
                                {key}
                                {!FieldArray.includes(key) && (
                                  <span
                                    className="text-danger"
                                    style={{ fontSize: 10 }}
                                  >
                                    invalid field name
                                  </span>
                                )}
                              </th>
                            ))}
                        </tr>
                      </thead>

                      <tbody>
                        {excelData.slice(0, 100).map((row, rowIndex) => (
                          <tr key={rowIndex}>
                            {Object.values(row).map((cell, cellIndex) => (
                              <td
                                key={cellIndex}
                                className={` text-center ${
                                  rowIndex % 2 == 1 && "bg-light"
                                } `}
                              >
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ExcelImport)
