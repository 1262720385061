import React, { Fragment, useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import { Col, Row, Card, CardBody, Table, CardImg, Button } from "reactstrap"
import ListCom from "./ListCom"
import { __generateRandomString } from "constants/constantfun"
import noImage from "../../../assets/images/no_image.png"
import ReactToPrint from "react-to-print"

const List = ({ companionList, count }) => {
  const componentRef = React.useRef()
  return (
    <React.Fragment>
      {companionList && companionList?.length > 0 && (
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-success  mx-2 ">Download</button>
          )}
          content={() => componentRef.current}
        />
      )}
      <Row>
        <Col xs="12">
          {companionList ? (
            companionList.length > 0 ? (
              <>
                <div className=" justify-content-end d-flex h6">
                  Report: {count}
                </div>
                <div ref={componentRef}>
                  <Fragment>
                    <Row>
                      {companionList.map((data, i) => (
                        <ListCom
                          data={data}
                          index={i}
                          key={__generateRandomString(10)}
                        />
                      ))}
                    </Row>
                  </Fragment>
                </div>
              </>
            ) : (
              <>{NoDataFound()}</>
            )
          ) : (
            <>{Loader()}</>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )

  function NoDataFound() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <CardImg
            className="img-fluid"
            src={noImage}
            alt=""
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              width: "auto",
            }}
          />
        </Row>
      </>
    )
  }
  function Loader() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <HashLoader color="#36d7b7" />
        </Row>
      </>
    )
  }
}

export default List
