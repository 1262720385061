import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __getApiData,
  __makeGetCallDashboardGetRequest,
  __makeGetCountPostRequest,
  __makeGetGalleryGetRequest,
  __postApiData,
} from "constants/apis"

const OwnMessage = props => {
  const [state, setState] = useState({
    template_id: "",
    send_to: "",
    more_numbers: "",
    error_message: "",
    success_message: "",
    loading: false,
    template: [],
    variables: "",
    template_name: "",
    filter: null,
    isFilter: false,
    userCount: 0,
    img_url: "",
    galleryList: [],
  })
  const {
    template_id,
    more_numbers,
    error_message,
    success_message,
    loading,
    template,
    template_message,
    template_name,
    img_url,
    galleryList,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleSubmit = () => {
    __postApiData("/api/whatsapp/schedule_whatsapp", {
      template_id,
      template_message,
      template_name: template_name,
      isFilter: false,
      more_numbers: more_numbers
        ? more_numbers.split(",").map(num => num.trim())
        : [],
      img_url: img_url ? "https://riwebsoft.com/schoolfiles/" + img_url : null,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ success_message: res.response.response_message })
          window.alert(res.response.response_message)
          return
        }
        window.alert(res.response.response_message)
      })
      .catch(error => {
        console.error(error)
        return updateState({ error_message: "Failed" })
      })
  }

  const __handleGetGallery = () => {
    __makeGetGalleryGetRequest()
      .then(res => {
        if (res.response.response_code === "200") {
          updateState({ galleryList: res?.data?.reverse() })
        } else {
          // setgalleryList([])
        }
      })
      .catch(error => {
        console.error(error)
        // setgalleryList([])
      })
  }

  useEffect(() => {
    __handleGetGallery()
  }, [])

  const __GetTemplateSubmit = () => {
    __getApiData("/api/whatsapp/tempate")
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          updateState({ template: res.data })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __GetTemplateSubmit()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("WhatsApp ")}
            breadcrumbItem={props.t("Own Number")}
          />

          <Card>
            <CardBody>
              <Row>
                <Col sm={4} md={4} lg={3}>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      onChange={e => {
                        updateState({
                          template_id: template.find(
                            item => item.template_name === e.target.value
                          )?._id,
                          template_message:
                            template.find(
                              item => item.template_name === e.target.value
                            )?.template_message || "",
                          template_name: e.target.value,
                        })
                      }}
                    >
                      <option value={""}>Choose ...</option>
                      {template.map((item, i) => (
                        <option key={i} value={item.template_name}>
                          {item.template_name}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="floatingSelectGrid">Template Name</label>
                  </div>
                </Col>
              </Row>

              <Row className="">
                <Col>
                  <div className="form-floating mb-3">
                    <Input
                      type="textarea"
                      className="form-control"
                      id="floatingnameInput"
                      placeholder="Enter..."
                      name="name"
                      value={template_message}
                      style={{ minHeight: 200 }}
                      disabled={template_message ? false : true}
                      onChange={e => {
                        updateState({ template_message: e.target.value })
                      }}
                    />
                    <label htmlFor="floatingnameInput">Tempate</label>
                  </div>
                </Col>
              </Row>

              {template.find(item => item._id === template_id)?.msgType ==
                "Media" && (
                <Row>
                  <Col sm={12}>
                    <div className="form-floating mb-3">
                      <select
                        defaultValue="0"
                        className="form-select"
                        onChange={e => {
                          updateState({ img_url: e.target.value })
                        }}
                        value={img_url}
                      >
                        <option value="">Choose Image</option>
                        {galleryList.map((item, i) => (
                          <option key={i} value={item.file_path}>
                            {item.file_name}
                          </option>
                        ))}
                      </select>
                      <label htmlFor="floatingSelectGrid">Image</label>
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col sm={12} md={12}>
                  <Input
                    type="textarea"
                    id="textarea"
                    onChange={e => {
                      updateState({ more_numbers: e.target.value })
                    }}
                    value={more_numbers}
                    rows="3"
                    style={{
                      height: "250px",
                    }}
                    placeholder={`Note: Add more  mobile number.\nExample :\n\n9898000001,\n9099000002,\n9898000003,...`}
                    invalid={error_message ? true : false}
                    valid={success_message ? true : false}
                  />
                  <FormFeedback type="invalid">{error_message}</FormFeedback>
                  <FormFeedback>{success_message}</FormFeedback>
                </Col>
              </Row>

              <div className="col-md-12 col-sm-12">
                <Button
                  className="btn btn-bg my-4 float-end"
                  onClick={__handleSubmit}
                >
                  Schedule Message
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(OwnMessage)
