import { __makeAssignSmsTemplatePostRequest } from "constants/apis"
import { __hasAnyValue } from "constants/constantfun"
import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
const initialState = {
  email: "",
  template_name: "",
  template_message: "",
  type: "WhatsApp",
  msgType: "",
  mediaType: "",
}

const AddBox = () => {
  const [state, setState] = useState({
    ...initialState,
  })
  const [error, setError] = useState({
    ...initialState,
  })
  const {
    email,
    template_name,
    template_id,
    template_message,
    type,
    msgType,
    mediaType,
  } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  const __handleSubmit = () => {
    let alertdata = {
      ...initialState,
      type: "",
    }

    if (!email.trim()) {
      alertdata.email = "Fill leader email id"
    }
    if (!template_name.trim()) {
      alertdata.template_name = "Fill template name"
    }

    if (!template_message.trim()) {
      alertdata.template_message = "Fill template message"
    }
    setError(prv => ({
      ...prv,
      ...alertdata,
    }))
    console.log(alertdata)

    if (__hasAnyValue(alertdata)) {
      return
    }
    __makeAssignSmsTemplatePostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setError(prv => ({
            ...prv,
            success: res.response.response_message,
          }))
        } else if (res.response.response_code === "400") {
          setError(prv => ({
            ...prv,
            email: res.response.response_message,
          }))
        } else {
          setError(prv => ({
            ...prv,
            email: " ",
            template_name: " ",
            template_id: " ",
            template_message: res.response.response_message,
          }))
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardBody style={{ minHeight: "75vh" }}>
            <CardTitle className="mb-4">Assign SMS Template</CardTitle>
            <Row>
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={email}
                    onChange={e => {
                      updateState({ email: e.target.value })
                    }}
                    invalid={error.email ? true : false}
                  />
                  <FormFeedback type="invalid">{error.email}</FormFeedback>
                  <label htmlFor="floatingnameInput">Leader Email</label>
                </div>
              </Col>
            </Row>
            <CardTitle className="mb-4">SMS Template Details</CardTitle>

            <Row className="mt-4">
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={template_name}
                    onChange={e => {
                      updateState({ template_name: e.target.value })
                    }}
                    invalid={error.template_name ? true : false}
                  />
                  <label htmlFor="floatingnameInput">Tempate Name</label>
                  <FormFeedback type="invalid">
                    {error.template_name}
                  </FormFeedback>
                </div>
              </Col>

              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    selected={type}
                    onChange={e => {
                      console.log(e.target.value)
                      updateState({ type: e.target.value })
                    }}
                  >
                    {/* <option value="SMS">SMS</option> */}
                    <option value="WhatsApp">WhatsApp</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Template Type</label>
                </div>
              </Col>
              <Col sm={4} md={4} lg={3}>
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    selected={msgType}
                    onChange={e => {
                      console.log(e.target.value)
                      updateState({ msgType: e.target.value, mediaType: "" })
                    }}
                  >
                    <option value="">choose...</option>

                    <option value="text">Text</option>
                    <option value="Media">Media</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Message Type</label>
                </div>
              </Col>
              {msgType == "Media" && (
                <Col sm={4} md={4} lg={3}>
                  <div className="form-floating mb-3">
                    <select
                      className="form-select"
                      selected={mediaType}
                      onChange={e => {
                        console.log(e.target.value)
                        updateState({ mediaType: e.target.value })
                      }}
                    >
                      <option value="">choose...</option>
                      <option value="image">Image</option>
                    </select>
                    <label htmlFor="floatingSelectGrid">Media Type</label>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="">
              <Col>
                <div className="form-floating mb-3">
                  <Input
                    type="textarea"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={template_message}
                    onChange={e => {
                      updateState({ template_message: e.target.value })
                    }}
                    invalid={error.template_message ? true : false}
                    valid={error.success ? true : false}
                    style={{ minHeight: 200 }}
                  />
                  <label htmlFor="floatingnameInput">Tempate Message</label>
                  <FormFeedback type="invalid">
                    {error.template_message}
                  </FormFeedback>
                  <FormFeedback valid>{error.success}</FormFeedback>
                </div>
              </Col>
            </Row>
            <div className=" py-3">
              <Button onClick={__handleSubmit} className="btn btn-bg float-end">
                Save
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AddBox
