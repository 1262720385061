import { __HOST } from "constants/apis"
import React, { useState } from "react"
import { Col, Input, Progress, Row } from "reactstrap"

const ShowPollDetails = ({ state }) => {
  return (
    <React.Fragment>
      <Row>
        <Col sm={12}>
          <div className="form-floating  mb-3">
            <Input
              type="textarea"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={state?.message}
              style={{ minHeight: 80 }}
            />
            <label htmlFor="floatingnameInput">Message</label>
          </div>
        </Col>
      </Row>
      {state?.options?.map((item, index) => {
        return (
          <div key={index}>
            <Row className="mb-0">
              <Col sm={6}>
                <div className="form-floating  mb-1">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={item.name}
                  />
                  <label htmlFor="floatingnameInput">Option {index + 1}</label>
                </div>
              </Col>
              <Col>
                <div className=" ">
                  <div className="p-0">
                    {!item?.path ? (
                      ""
                    ) : (
                      <img
                        src={__HOST() + "/" + item.path}
                        alt="img"
                        width={60}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className=" mb-3 col-sm-6 ">
                <div className="text-center">
                  {item.count} -{" "}
                  {state.total_votes != 0
                    ? ((item.count / state.total_votes) * 100).toFixed(1)
                    : 0}
                  %
                </div>
                <Progress
                  style={{
                    height: "5px",
                  }}
                  color={"primary"}
                  value={
                    state.total_votes != 0
                      ? (item.count / state.total_votes) * 100
                      : 0
                  }
                />
              </div>
            </div>
          </div>
        )
      })}
      <div className="text-end h5">Total Votes : {state?.total_votes}</div>
    </React.Fragment>
  )
}

export default ShowPollDetails
