import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { __formatDate, __generateRandomString } from "constants/constantfun"
import { withTranslation } from "react-i18next"
import { __getApiData } from "constants/apis"
import { HashLoader } from "react-spinners"

const WhatsAppHistory = props => {
  const [history, sethistory] = useState([])

  const [loading, setloading] = useState(false)
  const __handleGetHistory = () => {
    setloading(true)
    __getApiData("/api/whatsapp/get_history_whatsapp")
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          sethistory(res.data)
        }
        setloading(false)
      })
      .catch(error => {
        console.error(error)
        setloading(false)
      })
  }

  useEffect(() => {
    __handleGetHistory()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("WhatsApp")}
            breadcrumbItem={props.t("Messages History")}
          />

          <Card>
            <CardBody>
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Template Name</th>
                          <th className="text-center">Total Send</th>
                          <th className="text-center">Total Numbers</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Status</th>
                        </tr>
                      </thead>

                      <tbody>
                        {history.map((item, i) => (
                          <tr className="bg-danger" key={i + 1}>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {i + 1}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.template_name || "-"}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.numbers.length}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.total_number}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {__formatDate(item.createdAt)}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {item.status == "Failed" && (
                                <span className="font-size-11 badge-soft-danger badge bg-secondary">
                                  {item.status}
                                </span>
                              )}
                              {item.status == "Progress" && (
                                <span className="font-size-11 badge-soft-info badge bg-secondary">
                                  {item.status}
                                </span>
                              )}
                              {item.status == "Completed" && (
                                <span className="font-size-11 badge-soft-success badge bg-secondary">
                                  {item.status}
                                </span>
                              )}
                            </td>
                            {/* <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            ></td> */}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  {loading && (
                    <div className="d-flex justify-content-center my-5 py-3">
                      <HashLoader color="#36d7b7" />
                    </div>
                  )}
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(WhatsAppHistory)
