import React, { Fragment, useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import {
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  Modal,
  ModalHeader,
} from "reactstrap"
import { __generateRandomString } from "constants/constantfun"
import VoterListPdf from "./VoterListPdf"
import CastWisePdf from "./CartWisePdf"
import Alpha from "./Alpha"
import FemaleVoterPdf from "./FemaleVoterPdf"
import MaleVoterPdf from "./MaleVoterPdf"
import WardBisePdf from "./WardBisePdf"
import BlockWisePdF from "./BlockWisePdF"
const List = ({ companionList }) => {
  const urlParams = new URLSearchParams(window.location.search)

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(
    urlParams.get("type") == "cast" ? true : false
  )
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [modal4, setModal4] = useState(false)
  const [modal5, setModal5] = useState(
    urlParams.get("type") == "ward" ? true : false
  )
  const [modal6, setModal6] = useState(
    urlParams.get("type") == "booth" ? true : false
  )

  const toggle = () => setModal(!modal)
  const toggle1 = () => setModal1(!modal1)
  const toggle2 = () => setModal2(!modal2)
  const toggle3 = () => setModal3(!modal3)
  const toggle4 = () => setModal4(!modal4)
  const toggle5 = () => setModal5(!modal5)
  const toggle6 = () => setModal6(!modal6)

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>Voter List</ModalHeader>
        <VoterListPdf />
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1} size="xl">
        <ModalHeader toggle={toggle1}>Cast Wise List</ModalHeader>
        <CastWisePdf />
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} size="xl">
        <ModalHeader toggle={toggle2}> Alphabetical Order</ModalHeader>
        <Alpha />
      </Modal>
      <Modal isOpen={modal3} toggle={toggle3} size="xl">
        <ModalHeader toggle={toggle3}> Female Voter List</ModalHeader>
        <FemaleVoterPdf />
      </Modal>
      <Modal isOpen={modal4} toggle={toggle4} size="xl">
        <ModalHeader toggle={toggle4}> Male Voter List</ModalHeader>
        <MaleVoterPdf />
      </Modal>
      <Modal isOpen={modal5} toggle={toggle5} size="xl">
        <ModalHeader toggle={toggle5}> Ward Wise List</ModalHeader>
        <WardBisePdf />
      </Modal>
      <Modal isOpen={modal6} toggle={toggle6} size="xl">
        <ModalHeader toggle={toggle6}>Polling Booths Wise List</ModalHeader>
        <BlockWisePdF />
      </Modal>
      <Row>
        <Col xs="12">
          <Fragment>
            <Row>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">Voter List</CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">
                        Alphabetical Order
                      </CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle2()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">CastWise</CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle1()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">Ward Wise </CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle5()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">
                        Polling Booths Wise
                      </CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle6()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">
                        Female Voter List
                      </CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle3()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="d-flex ">
                  <Card className="flex-grow-1">
                    <CardBody className="d-flex justify-content-between align-items-center">
                      <CardText className="  p-0 m-0">Male Voter List</CardText>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                        alt=""
                        height={40}
                        className="float-end"
                        onClick={() => toggle4()}
                      />
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </Fragment>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default List
