import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __HOST,
  __makeCreatePolePostRequest,
  __makeCreateWorkPostRequest,
  __makeDeletePoleStatusDeleteRequest,
  __makeGetPoleListPostRequest,
  __makeGetWorkPostRequest,
  __makeUpdatePoleStatusPutRequest,
  __makeUploadImagePostRequest,
} from "constants/apis"
import ShowPollDetails from "./comp/ShowPollDetails"

const CreatePole = props => {
  const [list, setlist] = useState(null)
  const [arrayState, setArrayState] = useState([{ name: "", path: "" }])
  const [state, setState] = useState({
    message: "",
    polldata: null,
  })
  const { message, polldata } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [modal1, setModal1] = useState(false)
  const toggle1 = () => {
    setModal1(!modal1)
    updateWork({ polldata: null })
  }

  const __hnadleChangeValue = (index, key, newValue) => {
    setArrayState(prevState => {
      const newArray = [...prevState]
      if (index >= 0 && index < newArray.length) {
        newArray[index] = {
          ...newArray[index],
          [key]: newValue,
        }
      }
      return newArray
    })
  }
  const __handleWork = () => {
    __makeCreatePolePostRequest({ message: state.message, options: arrayState })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          toggle()
          setlist([res?.data, ...list])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetWork = filter => {
    __makeGetPoleListPostRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        } else {
          setlist([])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleImageUpload = (image, index) => {
    const formData = new FormData()
    formData.append("image_one", image[0])
    __makeUploadImagePostRequest(formData)
      .then(res => {
        console.log(res)
        __hnadleChangeValue(index, "path", res.data.path)
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleUpdatePollStatus = (id, status) => {
    __makeUpdatePoleStatusPutRequest(id, { status })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleDeletePoll = id => {
    __makeDeletePoleStatusDeleteRequest(id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetWork({})
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={modal} size="xl">
        <ModalHeader toggle={toggle}> Create Poll </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <div className="form-floating  mb-3">
                <Input
                  type="textarea"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={message}
                  onChange={e => {
                    updateWork({ message: e.target.value })
                  }}
                  style={{ minHeight: 80 }}
                />
                <label htmlFor="floatingnameInput">Message</label>
              </div>
            </Col>
          </Row>
          {arrayState.map((item, index) => {
            return (
              <Row key={index}>
                <Col sm={6}>
                  <div className="form-floating  mb-3">
                    <Input
                      type="text"
                      className="form-control"
                      id="floatingnameInput"
                      placeholder="Enter..."
                      name="name"
                      value={item.name}
                      onChange={e => {
                        __hnadleChangeValue(index, "name", e.target.value)
                      }}
                    />
                    <label htmlFor="floatingnameInput">
                      Option {index + 1}
                    </label>
                  </div>
                </Col>
                <Col>
                  <div className=" ">
                    <Input
                      type="file"
                      className="form-control d-none "
                      id={"imageinput" + index}
                      placeholder="Enter..."
                      name="name"
                      onChange={e => {
                        console.log(e.target.files)
                        __handleImageUpload(e.target.files, index)
                      }}
                      accept="image/jpeg,image/png,image/jpg"
                    />
                    <label htmlFor={"imageinput" + index} className="p-0">
                      {!item.path ? (
                        <span className="btn btn-light  w-sm">
                          <i className="mdi mdi-upload d-block font-size-16"></i>{" "}
                          Upload Image
                        </span>
                      ) : (
                        <img
                          src={
                            "https://digitalchunavparchar.com/uploads/" +
                            item.filename
                          }
                          alt="img"
                          width={60}
                        />
                      )}
                    </label>
                    {arrayState.length == index + 1 ? (
                      <button
                        className="btn btn-dark mx-3 "
                        onClick={() => {
                          setArrayState(prevState => {
                            return [...prevState, { name: "", path: "" }]
                          })
                        }}
                      >
                        <i className="bx bx-plus d-block font-size-16 py-1"></i>
                      </button>
                    ) : (
                      <button
                        className="btn btn-danger mx-3 "
                        onClick={() => {
                          setArrayState(prevState => {
                            return prevState.filter(
                              (item, i) => index != i && item
                            )
                          })
                        }}
                      >
                        <i className="bx bx-x d-block font-size-16 py-1"></i>
                      </button>
                    )}
                  </div>
                </Col>

                {/* <Col
                  sm={1}
                  className="d-flex justify-content-center align-items-center mb-3"
                >
                  <button className="btn btn-danger  ">
                    <i className="mdi mdi-upload d-block font-size-16"></i>
                  </button>
                </Col> */}
              </Row>
            )
          })}
          <Row>
            <Col>
              <Button
                onClick={() => __handleWork()}
                className="btn btn-success float-end"
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal1} toggle={toggle1} size="xl">
        <ModalHeader toggle={toggle1}> Poll Details </ModalHeader>
        <ModalBody>
          <ShowPollDetails state={polldata} />
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Polls")}
          />

          <Card>
            <CardBody>
              <Row>
                <Col className="justify-content-end ">
                  <Button
                    onClick={toggle}
                    className="btn btn-bg bg-gradient float-end"
                  >
                    Add +
                  </Button>
                </Col>
              </Row>

              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Message</th>
                          <th className="text-center">Number of Options</th>
                          <th className="text-center">Total Voters</th>
                          <th className="text-center">CreateAt</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {list?.map((data, i) => (
                          <tr className="bg-danger" key={i + 1}>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {i + 1}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.message.slice(0, 30)}...
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.options.length}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.total_votes}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {__formatDate(data.createdAt)}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.status ? (
                                <span
                                  onClick={() =>
                                    __handleUpdatePollStatus(data._id, false)
                                  }
                                  className="bx bx-toggle-right text-success fs-3"
                                ></span>
                              ) : (
                                <span
                                  onClick={() =>
                                    __handleUpdatePollStatus(data._id, true)
                                  }
                                  className="bx bx-toggle-left text-secondary fs-3"
                                ></span>
                              )}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              <a
                                href={"/poll-user/" + data._id}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <span className="bx bx-link fs-4" />
                              </a>
                              <a href="#">
                                <span
                                  className="bx bxs-show fs-4 mx-2 text-secondary"
                                  onClick={() => {
                                    toggle1()
                                    updateWork({ polldata: data })
                                  }}
                                />
                              </a>
                              <a href="#">
                                <span
                                  className="bx bxs-trash fs-5 mx-2 text-secondary"
                                  onClick={() => {
                                    __handleDeletePoll(data._id)
                                  }}
                                />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CreatePole)
