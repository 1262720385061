import {
  __makeGetCastNoListGetRequest,
  __makeGetPartNoListGetRequest,
  __makeGetTehsilListGetRequest,
  __makeGetWardNoListGetRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
const anotherField = {
  family_eng: "family_hin",
}
const Filter = ({ __handleGetGallery }) => {
  const urlParams = new URLSearchParams(window.location.search)

  const [field, setfield] = useState({
    name_eng: "",
    age_min: urlParams.get("min") || "",
    age_max: urlParams.get("max") || "",
    mobile: "",
    sex: urlParams.get("s") || "",
    dob: urlParams.get("d") || "",
    todaybirth: urlParams.get("todaybirth") || "",
    ward_name: urlParams.get("stream") || "",
    tehsil_hin: urlParams.get("class") || "",
    pollingbooth: urlParams.get("location") || "",
    category: urlParams.get("category") || "",
  })

  const {
    name_eng,
    family_eng,
    age_min,
    age_max,
    mobile,
    sex,
    ward_name,
    tehsil_hin,
    pollingbooth,
    category,
  } = field

  const updateFilter = data => setfield(prv => ({ ...prv, ...data }))

  const __handleSubmit = () => {
    let filterArray = []
    for (const key in field) {
      if (field.hasOwnProperty(key) && field[key] !== "") {
        console.log(key)
        console.log(field[key])

        if (anotherField[key]) {
          filterArray.push({
            $or: [
              { [key]: { $regex: field[key], $options: "i" } },
              { [anotherField[key]]: { $regex: field[key], $options: "i" } },
            ],
          })
        } else if (key == "age_min" || key == "age_max") {
          filterArray.push({
            dob: {
              $lte: new Date(
                new Date().setFullYear(new Date().getFullYear() - age_min)
              ),
              $gte: new Date(
                new Date().setFullYear(new Date().getFullYear() - age_max)
              ),
            },
          })
        } else if (key == "dob") {
          filterArray.push({
            dob: { $exists: true, $ne: null, $ne: "" },
          })
        } else if (key == "todaybirth") {
          filterArray.push({
            dob: { $regex: field[key], $options: "i" },
          })
        } else {
          console.log("else")
          filterArray.push({ [key]: { $regex: field[key], $options: "i" } })
        }
      }
    }

    filterArray.push(
      localStorage.getItem("type") == "All"
        ? {}
        : {
            pollingbooth: {
              $in: localStorage.getItem("type").split(","),
            },
          }
    )
    console.log(filterArray)
    __handleGetGallery({
      filter: {
        $and: filterArray,
      },
      search: true,
    })
  }

  const [tehsilList, setTehsil] = useState([])
  const [partList, setpart] = useState([])
  const [wardList, setward] = useState([])
  const [castList, setCast] = useState([])

  const __handlelist = () => {
    __makeGetTehsilListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setTehsil(
            res?.data.sort((a, b) => {
              return parseInt(a) - parseInt(b)
            })
          )
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }
  const __handlelist1 = () => {
    __makeGetPartNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setpart(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handlelist2 = () => {
    __makeGetWardNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setward(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handlelist3 = () => {
    __makeGetCastNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setCast(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handlelist()
    __handlelist1()
    __handlelist2()
    __handlelist3()
  }, [])
  useEffect(() => {
    console.log(urlParams)
    // if (urlParams.get("tehsil_nm_en") && tehsil_eng) {
    //   __handleSubmit()
    // } else if (urlParams.get("section_nm_v") && block) {
    //   __handleSubmit()
    // } else {
    __handleSubmit()
  }, [])

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col sm={4} md={3} className="d-flex gap-2">
                <div className="form-floating  mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={age_min}
                    onChange={e => {
                      updateFilter({ age_min: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">Age (min) </label>
                </div>
                <div className="form-floating  mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={age_max}
                    onChange={e => {
                      updateFilter({ age_max: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">Age (max) </label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating  mb-3">
                  <Input
                    type="text"
                    className="form-control"
                    id="floatingnameInput"
                    placeholder="Enter..."
                    name="name"
                    value={mobile}
                    onChange={e => {
                      updateFilter({ mobile: e.target.value })
                    }}
                  />
                  <label htmlFor="floatingnameInput">Mobile </label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ sex: e.target.value })
                    }}
                    value={sex}
                  >
                    <option value="">Choose Gender</option>
                    <option value="F">Female</option>
                    <option value="M">Male</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Works with selects</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ category: e.target.value })
                    }}
                    value={category}
                  >
                    <option value="">Choose Category</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <label htmlFor="floatingSelectGrid">Category</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ tehsil_hin: e.target.value })
                    }}
                    value={tehsil_hin}
                  >
                    <option value="">Choose Class</option>

                    {tehsilList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Class</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ ward_name: e.target.value })
                    }}
                    value={ward_name}
                  >
                    <option value="">Choose Stream</option>

                    {wardList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Stream</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ pollingbooth: e.target.value })
                    }}
                    value={pollingbooth}
                  >
                    <option value="">Choose Location</option>

                    {partList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Location</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <Button className="btn btn-bg " onClick={__handleSubmit}>
                  Search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Filter
