import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __makeGetGalleryGetRequest,
  __makeGetListPostRequest,
  __makeGetListReportPostRequest,
} from "constants/apis"
import Filter from "./comp/Filter"
import List from "./comp/List"
const ColorVoterList = props => {
  const [listdata, setgalleryList] = useState([])
  const [modal, setModal] = useState(false)
  const [filterstate, setFilter] = useState(null)
  const [count, setcount] = useState(null)
  const toggle = () => setModal(!modal)
  const [paginantion, setpaginantion] = useState({ limit: 500, offset: 0 })
  const { limit, offset } = paginantion

  const updatePage = data => setpaginantion(prv => ({ ...prv, ...data }))

  const __handleGetGallery = apidata => {
    setgalleryList(null)
    if (apidata.search) {
      setFilter(apidata.filter)
    }

    __makeGetListReportPostRequest({
      ...apidata,
      limit,
      skip: apidata.search ? 0 : apidata.skip,
    })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setgalleryList(res?.data.list)
          setcount(res?.data.count)
        } else {
          setgalleryList([])
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("Color report")}
          />
          <Filter __handleGetGallery={__handleGetGallery} />

          <List companionList={listdata} count={count} />
          {listdata && listdata.length > 0 && (
            <Row>
              <Col className="my-3 justify-content-end d-flex">
                <Button
                  className="btn btn-primary mx-2 px-4"
                  onClick={() => {
                    console.log(offset > 0)
                    offset > 0 &&
                      __handleGetGallery({
                        filter: filterstate,
                        search: false,
                        skip: offset - limit,
                      })
                    offset > 0 && updatePage({ offset: offset - limit })
                  }}
                  disabled={offset > 0 ? false : true}
                >
                  Prv
                </Button>
                <Button
                  className="btn btn-primary mx-2 px-4"
                  onClick={() => {
                    __handleGetGallery({
                      filter: filterstate,
                      search: false,
                      skip: offset + limit,
                    })
                    updatePage({ offset: offset + limit })
                  }}
                  disabled={offset + limit > count ? true : false}
                >
                  Next
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ColorVoterList)
