import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __makeCreateDevelopmentWorkPostRequest,
  __makeCreateWorkPostRequest,
  __makeGetDevelopmentWorkPostRequest,
  __makeGetWorkPostRequest,
} from "constants/apis"
import Filter from "./comp/Filter"

const DevelopmentWork = props => {
  //meta title

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [state, setState] = useState({
    sr_no: "",
    work_name: "",
    area_name: "",
    area: "",
    nagar: "",
    approved_year: "",
    approved_money: "",
    mdh: "",
  })

  const {
    sr_no,
    approved_year,
    area,
    nagar,
    approved_money,
    des,
    area_name,
    mdh,
    work_name,
  } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [list, setlist] = useState(null)

  const __handleWork = () => {
    __makeCreateDevelopmentWorkPostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          toggle()
          setlist([res?.data, ...list])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetWork = filter => {
    __makeGetDevelopmentWorkPostRequest(filter)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        } else {
          setlist([])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetWork({})
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}> </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={4} md={3} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={sr_no}
                  onChange={e => {
                    updateWork({ sr_no: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">S NO.</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={area_name}
                  onChange={e => {
                    updateWork({ area_name: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Area's name</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={nagar}
                  onChange={e => {
                    updateWork({ nagar: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">
                  {" "}
                  Village Council/Nagar
                </label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={area}
                  onChange={e => {
                    updateWork({ area: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Village/Ward</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={approved_year}
                  onChange={e => {
                    updateWork({ approved_year: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Approved Year</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={mdh}
                  onChange={e => {
                    updateWork({ mdh: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">mdh</label>
              </div>
            </Col>

            <Col sm={4} md={4} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="test"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={approved_money}
                  onChange={e => {
                    updateWork({ approved_money: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Approved Money</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating  mb-3">
                <Input
                  type="textarea"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={work_name}
                  onChange={e => {
                    updateWork({ work_name: e.target.value })
                  }}
                  // style={{ minHeight: 100 }}
                />
                <label htmlFor="floatingnameInput">Work's Name</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => __handleWork()}
                className="btn btn-success float-end"
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Development Work")}
          />
          <Row>
            <Col className="justify-content-end ">
              <Button
                onClick={toggle}
                className="btn btn-bg bg-gradient float-end"
              >
                Add +
              </Button>
            </Col>
          </Row>

          <Filter __handleGetWork={__handleGetWork} />
          <Card>
            <CardBody>
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Sr. No</th>
                          <th className="text-center">Area's name</th>
                          <th className="text-center">Village Council/Nagar</th>
                          <th className="text-center">Village/Ward</th>
                          <th className="text-center">Approved Year </th>
                          <th className="text-center">mdh</th>
                          <th className="text-center">Approved Money</th>
                          <th className="text-center">Work's Name</th>
                        </tr>
                      </thead>

                      <tbody>
                        {list?.map((data, i) => (
                          <tr className="bg-danger" key={i + 1}>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {i + 1}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.sr_no}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.area_name}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.nagar}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.area}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.approved_year}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.mdh}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.approved_money}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.work_name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DevelopmentWork)
