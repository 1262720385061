import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Row,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  __makeGetGalleryGetRequest,
  __makeGetListCountSortPostRequest,
  __makeGetListFirstTimeGetRequest,
  __makeGetListPostRequest,
} from "constants/apis"
import List from "./comp/List"
const FirstTimeVoting = props => {
  const [listdata, setgalleryList] = useState([])
  const [paginantion, setpaginantion] = useState({ limit: 500, offset: 0 })
  const { limit, offset } = paginantion
  const [count, setCount] = useState(null)

  const updatePage = data => setpaginantion(prv => ({ ...prv, ...data }))

  const __handleGetGallery = skip => {
    setgalleryList(null)

    __makeGetListFirstTimeGetRequest(limit, skip)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setgalleryList(res?.data.list)
          setCount(res?.data.count)
        } else {
          setgalleryList([])
        }
      })
      .catch(error => {
        console.error(error)
        setgalleryList([])
      })
  }

  useEffect(() => {
    __handleGetGallery(0)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboard")}
            breadcrumbItem={props.t("First Time Voting")}
          />
          <List companionList={listdata} count={count} />
          {listdata && listdata.length > 0 && (
            <Row>
              <Col className="my-3 justify-content-end d-flex">
                <Button
                  className="btn btn-primary mx-2 px-4"
                  onClick={() => {
                    console.log(offset > 0)
                    offset > 0 && __handleGetGallery(offset - limit)
                    offset > 0 && updatePage({ offset: offset - limit })
                  }}
                  disabled={offset > 0 ? false : true}
                >
                  Prv
                </Button>
                <Button
                  className="btn btn-primary mx-2 px-4"
                  onClick={() => {
                    __handleGetGallery(offset + limit)
                    updatePage({ offset: offset + limit })
                  }}
                  disabled={offset + limit > count ? true : false}
                >
                  Next
                </Button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(FirstTimeVoting)
