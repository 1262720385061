import { __formatDate } from "constants/constantfun"
import React, { useEffect, useState } from "react"
import {
  Button,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"

const ListTemp = ({
  __handleUpdateStatus,
  __handleUpdatetemp,
  __handleDeletetemp,
  data,
  i,
}) => {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const [modal3, setModal3] = useState(false)
  const toggle3 = () => setModal3(!modal3)

  const [state, setState] = useState({
    template_name: "",
    template_message: "",
  })
  const { template_name, template_message } = state

  const updateState = data => setState(state => ({ ...state, ...data }))

  console.log(data)

  useEffect(() => {
    updateState({ ...data })
  }, [])

  return (
    <>
      <Modal isOpen={modal3} toggle={toggle3} centered>
        <ModalBody className="p-2 my-5 m-2">
          <CardTitle className="text-center">
            Are you sure you want to Delete?
          </CardTitle>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button onClick={toggle3} className="btn-lg">
              No
            </Button>
            <Button
              className="btn btn-danger btn-lg"
              onClick={() => __handleDeletetemp(data._id)}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Edit Template</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={template_name}
                  onChange={e => {
                    updateState({ template_name: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Template Name</label>
              </div>
            </Col>
            <Col sm={12}>
              <div className="form-floating mb-3">
                <Input
                  type="textarea"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={template_message}
                  onChange={e => {
                    updateState({ template_message: e.target.value })
                  }}
                  style={{ height: 300 }}
                />
                <label htmlFor="floatingnameInput">Template</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={async () => {
                  await __handleUpdatetemp(data._id, state)
                  setModal(false)
                }}
                className="btn btn-success float-end"
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <tr className="bg-danger" key={i + 1}>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>{i + 1}</td>
        <td
          className={` text-center ${i % 2 == 1 && "bg-light"} `}
          style={{ textTransform: "capitalize" }}
        >
          {data.leader_email}
        </td>

        <td
          className={` text-center ${i % 2 == 1 && "bg-light"} `}
          style={{ textTransform: "capitalize" }}
        >
          {data.template_name}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {data.msgType}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {data.type}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {__formatDate(data.createdAt)}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {data.is_active ? (
            <span
              onClick={() => __handleUpdateStatus(data._id, false)}
              className="bx bx-toggle-right text-success fs-3"
            ></span>
          ) : (
            <span
              onClick={() => __handleUpdateStatus(data._id, true)}
              className="bx bx-toggle-left text-secondary fs-3"
            ></span>
          )}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
            <li>
              <a
                className="btn btn-sm btn-soft-info"
                id="edittooltip-10"
                href="#"
                onClick={toggle}
              >
                <i className="mdi mdi-pencil-outline"></i>
              </a>
            </li>
            <li>
              <a
                className="btn btn-sm btn-soft-danger"
                id="deletetooltip-10"
                href="#"
                onClick={toggle3}
              >
                <i className="mdi mdi-delete-outline"></i>
              </a>
            </li>
          </ul>
        </td>
      </tr>
    </>
  )
}

export default ListTemp
