import React from "react"
import { Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import Cards from "./comp/Cards"
import { __generateRandomString } from "constants/constantfun"

import img1 from "./icons8-analyze-96.png"
import img2 from "./icons8-print-96.png"
import img3 from "./report-black.png"
import img4 from "./report.png"
import img5 from "./alphabetical.png"
import img6 from "./sand.png"
import img7 from "./progrss.png"
import Summery from "./comp/Summery"
const CardList = [
  { img: img1, name: "Students List", path: "/student-ist" },
  // { img: img2, name: "Print", path: "/pdf-generator" },
  // { img: img3, name: "Black & White report", path: "/black-white-report" },
  // { img: img4, name: "Color report", path: "/color-report" },
  { img: img5, name: "Alphabetical Order", path: "/alphabetical-order-report" },
  // { img: img6, name: "Voter's Counting", path: "/counting" },
  // { img: img6, name: "Voter's Counting Tehsil Bies", path: "/counting-tehsil" },
  // { img: img1, name: "Visit Program", path: "/work" },
  // { img: img7, name: "Development Work", path: "/development-work" },
]
const Dashboard = props => {
  //meta title
  document.title = "Dashboard | School Near"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Summery />
          </Row>

          <Row>
            {CardList.map(item => (
              <Cards key={__generateRandomString(10)} cardData={item} />
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
