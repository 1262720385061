import axios from "axios"
import React from "react"
import {
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Row,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { __HOST } from "constants/apis"

const GalleryList = ({ cardData, __handleDeleteNotes }) => {
  const [modal1, setModal1] = React.useState(false)
  const [isFits, setisFits] = React.useState(false)
  const toggle1 = () => {
    setModal1(!modal1)
  }
  return (
    <React.Fragment>
      {isFits ? (
        <Lightbox
          mainSrc={"https://riwebsoft.com/schoolfiles/" + cardData.file_path}
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits)
          }}
        />
      ) : null}
      <Modal
        isOpen={modal1}
        fade={false}
        toggle={toggle1}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalBody>
          <Card className="btn-bg">
            <CardBody
              style={{ fontWeight: "600" }}
              className="text-center font-size-14"
            >
              Delete Image
            </CardBody>
          </Card>
          <p className="text-center">
            Are you sure you want to delete this Image!
          </p>
          <div className="d-flex gap-2 mt-3 justify-content-center">
            <Button
              onClick={toggle1}
              className="btn blue-bg  mx-2 bg-gradient py-1"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={async () => {
                setModal1(false)
                __handleDeleteNotes(cardData._id)
              }}
              className="btn btn-danger mx-2 bg-gradient py-1"
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Col lg={3} md={4} sm={5}>
        <Card className="btn-bg">
          <Row className="no-gutters align-items-center m-2">
            <Col
              sm={12}
              className=" rounded p-1 d-flex justify-content-center align-items-center border-bottom rounded-0 "
              style={{ height: "200px" }}
            >
              <CardImg
                onClick={() => {
                  setisFits(true)
                }}
                className="img-fluid h-100"
                src={"https://riwebsoft.com/schoolfiles/" + cardData.file_path}
                alt=""
              />
            </Col>
            <div className="d-flex align-items-center mt-2 mx-0 px-0 justify-content-center">
              <p className="my-auto">Image Name : </p>
              <CardTitle className=" my-auto mx-2 border-1">
                {cardData?.file_name}
              </CardTitle>
            </div>

            <span
              className=" d-flex align-items-center justify-content-end gap-2"
              style={{
                width: "100px",
                height: "40px",
                position: "absolute",
                right: "10px",
                top: "10px",
              }}
            >
              <span
                onMouseEnter={e =>
                  (e.target.className = "bx bx-trash  font-size-22 text-danger")
                }
                onMouseLeave={e =>
                  (e.target.className = "bx bx-trash  font-size-22 text-white")
                }
                onClick={toggle1}
                className="bx bx-trash  font-size-22 text-white"
                style={{ cursor: "pointer" }}
              />
            </span>
          </Row>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default GalleryList
