import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __makeCreatePostRequest,
  __makeCreateWorkPostRequest,
  __makeDeleteUserRequest,
  __makeGetVidhansabhaListGetRequest,
  __makeGetWorkPostRequest,
  __makeSignupPostRequest,
  __makeUpdateMobileUserStatusPutRequest,
  __makegetUsersGetRequest,
  __makegetteamUsersGetRequest,
} from "constants/apis"

const TeamManagement = props => {
  //meta title
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [state, setState] = useState({
    name: "",
    phone: "",
    password: "",
  })

  const { name, phone, password } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [list, setlist] = useState(null)

  const __handleWork = () => {
    __makeCreatePostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          toggle()
          setlist([res?.data, ...list])
        } else {
          window.alert(res.response.response_message)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetWork = filter => {
    __makegetteamUsersGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        } else {
          setlist([])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handleUpdateStatus = (id, status) => {
    __makeUpdateMobileUserStatusPutRequest(id, { status })
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  //

  const __handleDeleteUser = id => {
    return __makeDeleteUserRequest(id)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
          return "done"
        }
        window.alert(res.response.response_message)
        return "fail"
      })
      .catch(error => {
        console.error(error)
        window.alert("Failed")
        return "fail"
      })
  }

  useEffect(() => {
    __handleGetWork({})
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}> </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={name}
                  onChange={e => {
                    updateWork({ name: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Name</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={phone}
                  onChange={e => {
                    updateWork({ phone: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Phone</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={password}
                  onChange={e => {
                    updateWork({ password: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Password</label>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                onClick={() => __handleWork()}
                className="btn btn-success float-end"
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Team Management")}
          />
          {localStorage.getItem("login") != "superadmin" && (
            <Row>
              <Col className="justify-content-end ">
                <Button
                  onClick={toggle}
                  className="btn btn-bg bg-gradient float-end"
                >
                  Add +
                </Button>
              </Col>
            </Row>
          )}

          {/* <Filter __handleGetWork={__handleGetWork} /> */}
          <Card>
            <CardBody>
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Name</th>
                          <th className="text-center">Vidhanshabha</th>
                          <th className="text-center">Phone</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Status</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {list?.map((data, i) => (
                          <tr className="bg-danger" key={i + 1}>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {i + 1}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.name}
                            </td>

                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                              style={{ textTransform: "capitalize" }}
                            >
                              {data.vidhanshabha}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.phone}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {__formatDate(data.createdAt)}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.status ? (
                                <span
                                  onClick={() =>
                                    __handleUpdateStatus(data._id, false)
                                  }
                                  className="bx bx-toggle-right text-success fs-3"
                                ></span>
                              ) : (
                                <span
                                  onClick={() =>
                                    __handleUpdateStatus(data._id, true)
                                  }
                                  className="bx bx-toggle-left text-secondary fs-3"
                                ></span>
                              )}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
                                {/* <li>
                                  <a
                                    className="btn btn-sm btn-soft-info"
                                    id="edittooltip-10"
                                    href="#"
                                    // onClick={toggle2}
                                  >
                                    <i className="mdi mdi-pencil-outline"></i>
                                  </a>
                                </li> */}
                                <li>
                                  <a
                                    className="btn btn-sm btn-soft-danger"
                                    id="deletetooltip-10"
                                    href="#"
                                    onClick={() => __handleDeleteUser(data._id)}
                                  >
                                    <i className="mdi mdi-delete-outline"></i>
                                  </a>
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(TeamManagement)
