import React, { useState } from "react"
import GalleryList from "./GalleryList"
import {
  CardImg,
  Col,
  FormFeedback,
  Modal,
  ModalBody,
  Progress,
  Row,
} from "reactstrap"
import noImage from "../../../assets/images/no_image.png"
import failedImage from "../../../assets/images/failed_img.png"
import successImage from "../../../assets/images/success_img.png"
import { __makeDeleteGalleryDeleteRequest } from "constants/apis"
import { HashLoader } from "react-spinners"

const Gallery = ({ galleryList, setgalleryList }) => {
  const [progress, setProgress] = useState(null)
  const [modal, setModal] = useState(false)
  const [error, setError] = useState({
    failed: "",
    success: "",
  })

  const __handleDeleteNotes = _id => {
    setError({})
    console.log(_id)
    setModal(true)
    setProgress(20)
    setTimeout(() => {
      setProgress(40)
    }, 50)
    __makeDeleteGalleryDeleteRequest(_id)
      .then(res => {
        setProgress(80)

        console.log(res)
        if (res.response.response_code === "200") {
          setgalleryList(galleryList.filter(image => image._id !== _id))
          setError(prv => ({
            ...prv,
            success: res.response.response_message,
          }))
        } else {
          setError(prv => ({
            ...prv,
            failed: res.response.response_message,
          }))
        }
        setProgress(100)
      })
      .catch(error => {
        console.error(error)
        setProgress(100)
        setError(prv => ({
          ...prv,
          failed: "Some Technical Issue! Please try after some time",
        }))
      })
  }

  return (
    <React.Fragment>
      {deleteProgress()}
      <Row>
        {!galleryList ? (
          <Row
            className=" d-flex justify-content-center  align-items-center "
            style={{ height: "50vh" }}
          >
            <HashLoader color="#36d7b7" />
          </Row>
        ) : galleryList?.length > 0 ? (
          galleryList.map((item, i) => (
            <GalleryList
              cardData={item}
              key={i}
              __handleDeleteNotes={__handleDeleteNotes}
            />
          ))
        ) : (
          <Row>
            <Col>
              <div className="d-flex justify-content-center py-5 flex-column align-items-center   ">
                <CardImg
                  className="img-fluid"
                  src={noImage}
                  alt=""
                  style={{
                    maxHeight: "300px",
                    maxWidth: "100%",
                    width: "auto",
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
      </Row>
    </React.Fragment>
  )

  function deleteProgress() {
    return (
      <Modal
        isOpen={modal}
        fade={false}
        toggle={() => {
          progress == 100 && setModal(!modal)
          setError({})
        }}
        className="top-50 left-50 mx-auto"
        style={{ transform: "translateY(-50%)" }}
      >
        <ModalBody>
          <div className="text-center">
            {error?.failed && (
              <>
                <div className="d-flex justify-content-center  flex-column align-items-center   ">
                  <CardImg
                    className="img-fluid"
                    src={failedImage}
                    alt=""
                    style={{
                      maxHeight: "200px",
                      maxWidth: "100%",
                      width: "auto",
                    }}
                  />
                </div>

                <p className="text-danger font-size-12 text-center">
                  {error?.failed}
                </p>
              </>
            )}
            {error?.success && (
              <>
                <div className="d-flex justify-content-center  flex-column align-items-center   ">
                  <CardImg
                    className="img-fluid"
                    src={successImage}
                    alt=""
                    style={{
                      maxHeight: "200px",
                      maxWidth: "100%",
                      width: "auto",
                    }}
                  />
                </div>
                <p className="text-success font-size-12 text-center">
                  {error?.success}
                </p>
              </>
            )}
          </div>
          <Progress
            className="my-3"
            style={{
              height: "5px",
            }}
            value={progress}
            color={error?.failed ? "danger" : "success"}
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default Gallery
