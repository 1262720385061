import React, { Fragment, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { __formatDate, __generateRandomString } from "constants/constantfun"
import {
  __makeCreateWorkPostRequest,
  __makeGetWorkPostRequest,
} from "constants/apis"
import Filter from "./comp/Filter"

const CreateWork = props => {
  //meta title

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [state, setState] = useState({
    sr_no: "",
    type: "",
    area: "",
    nagar: "",
    date: new Date(),
    remark: "",
    des: "",
  })

  const { sr_no, type, area, nagar, date, remark, des } = state
  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const [list, setlist] = useState(null)

  const __handleWork = () => {
    __makeCreateWorkPostRequest(state)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          toggle()
          setlist([res?.data, ...list])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const __handleGetWork = filter => {
    __makeGetWorkPostRequest(filter)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setlist(res?.data.reverse())
        } else {
          setlist([])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handleGetWork({})
  }, [])

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}> </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={4} md={3} lg={1}>
              <div className="form-floating  mb-3">
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={sr_no}
                  onChange={e => {
                    updateWork({ sr_no: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">S NO.</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                {/* <select
                  defaultValue="0"
                  value={type}
                  className="form-select"
                  onChange={e => {
                    updateWork({ type: e.target.value })
                  }}
                >
                  <option value="">Choose ....</option>
                </select>
                <label htmlFor="floatingSelectGrid">Type of Work</label> */}
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={type}
                  onChange={e => {
                    updateWork({ type: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Type of Work</label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                {/* <select
                  defaultValue="0"
                  className="form-select"
                  value={nagar}
                  onChange={e => {
                    updateWork({ nagar: e.target.value })
                  }}
                >
                  <option value="">Choose ....</option>
                </select>
                <label htmlFor="floatingSelectGrid">
                  Village Council/Nagar
                </label> */}
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={nagar}
                  onChange={e => {
                    updateWork({ nagar: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">
                  {" "}
                  Village Council/Nagar
                </label>
              </div>
            </Col>
            <Col sm={4} md={4} lg={3}>
              <div className="form-floating mb-3">
                {/* <select
                  defaultValue="0"
                  className="form-select"
                  value={area}
                  onChange={e => {
                    updateWork({ area: e.target.value })
                  }}
                >
                  <option value="">Choose ....</option>
                </select>
                <label htmlFor="floatingSelectGrid">Village/Ward</label> */}
                <Input
                  type="text"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={nagar}
                  onChange={e => {
                    updateWork({ nagar: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Village/Ward</label>
              </div>
            </Col>
            <Col sm={4} md={3} lg={2}>
              <div className="form-floating  mb-3">
                <Input
                  type="date"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={date}
                  onChange={e => {
                    updateWork({ date: e.target.value })
                  }}
                />
                <label htmlFor="floatingnameInput">Program's Date</label>
              </div>
            </Col>

            <Col sm={12} md={6} lg={6}>
              <div className="form-floating  mb-3">
                <Input
                  type="textarea"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={des}
                  onChange={e => {
                    updateWork({ des: e.target.value })
                  }}
                  style={{ minHeight: 100 }}
                />
                <label htmlFor="floatingnameInput">Program's Details</label>
              </div>
            </Col>
            <Col sm={12} md={6} lg={6}>
              <div className="form-floating  mb-3">
                <Input
                  type="textarea"
                  className="form-control"
                  id="floatingnameInput"
                  placeholder="Enter..."
                  name="name"
                  value={remark}
                  onChange={e => {
                    updateWork({ remark: e.target.value })
                  }}
                  style={{ minHeight: 100 }}
                />
                <label htmlFor="floatingnameInput">Remark</label>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => __handleWork()}
                className="btn btn-success float-end"
              >
                Save
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Visit Program")}
          />
          <Row>
            <Col className="justify-content-end ">
              <Button
                onClick={toggle}
                className="btn btn-bg bg-gradient float-end"
              >
                Add +
              </Button>
            </Col>
          </Row>

          <Filter __handleGetWork={__handleGetWork} />
          <Card>
            <CardBody>
              <Row>
                <Fragment>
                  <div className="table-responsive react-table">
                    <Table bordered hover className={"custom-header-css mt-3"}>
                      <thead className="table-light table-nowrap">
                        <tr>
                          <th className="text-center">#</th>
                          <th className="text-center">Sr. No</th>
                          <th className="text-center">Type of Work</th>
                          <th className="text-center">Village Council/Nagar</th>
                          <th className="text-center">Village/Ward</th>
                          <th className="text-center">Program's Date </th>
                          <th className="text-center">Program's Details</th>
                          <th className="text-center">Remark</th>
                        </tr>
                      </thead>

                      <tbody>
                        {list?.map((data, i) => (
                          <tr className="bg-danger" key={i + 1}>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {i + 1}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.sr_no}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.type}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.nagar}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.area}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {__formatDate(data.date)}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.des}
                            </td>
                            <td
                              className={` text-center ${
                                i % 2 == 1 && "bg-light"
                              } `}
                            >
                              {data.remark}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Fragment>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(CreateWork)
