import {
  __makeGetListPostRequest,
  __makeGetTehsilListGetRequest,
  __makeGetPartNoListGetRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap"
const anotherField = {
  fm_name: "fm_name_e",
  rln_fm_nm: "rln_fm_nm_e",
  tehsil_nm_en: "tehsil_nm_v",
}
const Filter = ({ __handleGetGallery }) => {
  const [field, setfield] = useState({
    SLNOINPART: "",
    fm_name: "",
    rln_fm_nm: "",
    IDCARD_NO: "",
    PART_NO: "",
    tehsil_nm_en: "",
    age_min: "",
    age_max: "",
    mobile: "",
    SEX: "",
    ac_nm_en: localStorage.getItem("type"),
  })

  const {
    SLNOINPART,
    fm_name,
    rln_fm_nm,
    IDCARD_NO,
    PART_NO,
    tehsil_nm_en,
    age_min,
    age_max,
    mobile,
    SEX,
  } = field

  const updateFilter = data => setfield(prv => ({ ...prv, ...data }))

  const __handleSubmit = () => {
    let filterArray = []
    for (const key in field) {
      if (field.hasOwnProperty(key) && field[key] !== "") {
        console.log(key)
        console.log(field[key])

        if (anotherField[key]) {
          filterArray.push({
            $or: [
              { [key]: { $regex: field[key], $options: "i" } },
              { [anotherField[key]]: { $regex: field[key], $options: "i" } },
            ],
          })
        } else if (key == "age_min" || key == "age_max") {
          filterArray.push({
            AGE: {
              $lt: age_max,
              $gt: age_min,
            },
          })
        } else {
          console.log("else")
          filterArray.push({ [key]: { $regex: field[key], $options: "i" } })
        }
      }
    }
    console.log(filterArray)
    __handleGetGallery({
      filter: {
        $and: filterArray,
      },
    })
  }

  const [tehsilList, setTehsil] = useState([])
  const [partList, setpart] = useState([])

  const __handlelist = () => {
    __makeGetTehsilListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setTehsil(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
  const __handlelist1 = () => {
    __makeGetPartNoListGetRequest()
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setpart(res?.data)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  useEffect(() => {
    __handlelist()
    __handlelist1()
  }, [])

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col sm={4} md={3} lg={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ tehsil_nm_en: e.target.value })
                    }}
                  >
                    <option value="">Choose Tehsil</option>

                    {tehsilList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Villoge</label>
                </div>
              </Col>

              <Col sm={4} md={3}>
                <div className="form-floating mb-3">
                  <select
                    defaultValue="0"
                    className="form-select"
                    onChange={e => {
                      updateFilter({ PART_NO: e.target.value })
                    }}
                  >
                    <option value="">Choose Tehsil</option>

                    {partList.map((item, i) => (
                      <option value={item} key={i}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelectGrid">Part no</label>
                </div>
              </Col>
              <Col sm={4} md={3}>
                <Button className="btn btn-bg " onClick={__handleSubmit}>
                  Search
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default Filter
