import {
  __makeGetLocationListGetRequest,
  __makeGetVidhansabhaListGetRequest,
} from "constants/apis"
import React, { useEffect, useState } from "react"
import { Button, CardText, Col, Input, Row } from "reactstrap"
import Select from "react-select"

const EditUser = ({ user, __handleUpdateUser, toggle2 }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    vidhanshabha: [],
    address: "",
    whatsapp_id: "",
    whatsapp_password: "",
    whatsapp_sendno: "",
  })

  const {
    name,
    email,
    password,
    vidhanshabha,
    address,
    whatsapp_id,
    whatsapp_password,
    whatsapp_sendno,
  } = state

  const updateWork = data => setState(prv => ({ ...prv, ...data }))

  const __handleOnSubmit = async () => {
    const assignLocation = JSON.parse(JSON.stringify(vidhanshabha))
      .map(item => item.value)
      .join(",")

    const data = {
      ...state,
      vidhanshabha: assignLocation,
    }
    if (!data?.password) {
      delete data.password
    }
    let res = await __handleUpdateUser(user._id, data)
    console.log(res)

    if (res == "done") toggle2()
  }

  useEffect(() => {
    updateWork({
      ...user,
      vidhanshabha: user.vidhanshabha.split(",").map(item => ({
        label: item,
        value: item,
      })),
    })
  }, [])

  const [partList, setpartList] = useState()

  const __handleGetParty = () => {
    __makeGetVidhansabhaListGetRequest()
      .then(res => {
        if (res.response.response_code === "200") {
          setpartList(res?.data)
        } else {
          setpartList([])
        }
      })
      .catch(error => {
        console.error(error)
        setpartList([])
      })
  }

  const [locationListing, setlocationListing] = useState([])

  const __handleLocation = id => {
    __makeGetLocationListGetRequest(id)
      .then(res => {
        if (res.response.response_code === "200") {
          const list = res.data.map(data => ({
            label: data.pollingbooth,
            value: data.pollingbooth,
          }))

          setlocationListing(list)
        } else {
          setpartList([])
        }
      })
      .catch(error => {
        console.error(error)
        setpartList([])
      })
  }

  useEffect(() => {
    __handleGetParty()
    localStorage.getItem("login") == "admin" && localStorage.clear()
  }, [])

  return (
    <>
      <Row>
        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={name}
              onChange={e => {
                updateWork({ name: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Name</label>
          </div>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={email}
              onChange={e => {
                updateWork({ email: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Email</label>
          </div>
        </Col>
        <Col sm={6} md={6} lg={6}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={password}
              onChange={e => {
                updateWork({ password: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Password</label>
          </div>
        </Col>

        <Col sm={12} md={12} lg={12}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={address}
              onChange={e => {
                updateWork({ address: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Address</label>
          </div>
        </Col>
      </Row>
      <div className="border-bottom border-2 mb-3"></div>
      <CardText>Whatsapp Credential</CardText>
      <Row>
        <Col sm={4} md={4} lg={4}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={whatsapp_id}
              onChange={e => {
                updateWork({ whatsapp_id: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Whatsapp User Id</label>
          </div>
        </Col>
        <Col sm={4} md={4} lg={4}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={whatsapp_password}
              onChange={e => {
                updateWork({ whatsapp_password: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Whatsapp Password</label>
          </div>
        </Col>
        <Col sm={4} md={4} lg={4}>
          <div className="form-floating mb-3">
            <Input
              type="text"
              className="form-control"
              id="floatingnameInput"
              placeholder="Enter..."
              name="name"
              value={whatsapp_sendno}
              onChange={e => {
                updateWork({ whatsapp_sendno: e.target.value })
              }}
            />
            <label htmlFor="floatingnameInput">Whatsapp Send Number</label>
          </div>
        </Col>
      </Row>

      <div className="border-bottom border-2 mb-3"></div>
      <Col sm={12} md={6} lg={6}>
        <div className="form-floating mb-3">
          <select
            defaultValue="0"
            className="form-select"
            onChange={e => {
              __handleLocation(e.target.value)
            }}
          >
            <option value="">Choose ....</option>
            {partList?.map((part, i) => (
              <>
                <option key={i} value={part}>
                  {part}
                </option>
              </>
            ))}
          </select>
          <label htmlFor="floatingSelectGrid">City</label>
        </div>
      </Col>
      <Col md={"12"} className="">
        <div className="mb-5">
          <label className="control-label">Assign Locations</label>
          <Select
            value={vidhanshabha}
            isMulti={true}
            onChange={e => {
              updateWork({ vidhanshabha: e })
            }}
            options={locationListing}
            className="select2-selection "
          />
        </div>
      </Col>
      <Row>
        <div>
          <Button
            onClick={() => __handleOnSubmit()}
            className="btn btn-success float-end"
          >
            Save
          </Button>
        </div>
      </Row>
    </>
  )
}

export default EditUser
