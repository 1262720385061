import { __formatDate } from "constants/constantfun"
import React, { useState } from "react"
import AssignPlan from "./AssignPlan"
import { Button, CardTitle, Modal, ModalBody, ModalHeader } from "reactstrap"
import EditUser from "./EditUser"

const ListData = ({
  data,
  __handleUpdateStatus,
  i,
  __handleUpdateUser,
  __handleDeleteUser,
}) => {
  const [modal1, setModal1] = useState(false)
  const toggle1 = () => setModal1(!modal1)
  const [modal2, setModal2] = useState(false)
  const toggle2 = () => setModal2(!modal2)
  const [modal3, setModal3] = useState(false)
  const toggle3 = () => setModal3(!modal3)

  return (
    <>
      <Modal isOpen={modal1} toggle={toggle1} centered>
        <ModalHeader toggle={toggle1}>Leader Plan </ModalHeader>
        <ModalBody>
          <AssignPlan userId={data._id} />
        </ModalBody>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} centered size={"lg"}>
        <ModalHeader toggle={toggle2}>Edit Leader </ModalHeader>
        <ModalBody>
          <EditUser
            user={data}
            __handleUpdateUser={__handleUpdateUser}
            toggle2={toggle2}
          />
        </ModalBody>
      </Modal>
      <Modal isOpen={modal3} toggle={toggle3} centered>
        <ModalBody className="p-2 my-5 m-2">
          <CardTitle className="text-center">
            Are you sure you want to Delete?
          </CardTitle>
          <div className="mt-3 d-flex justify-content-center gap-3">
            <Button onClick={toggle3} className="btn-lg">
              No
            </Button>
            <Button
              className="btn btn-danger btn-lg"
              onClick={() => __handleDeleteUser(data._id)}
            >
              Yes
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <tr className="bg-danger">
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>{i + 1}</td>
        <td
          className={` text-center ${i % 2 == 1 && "bg-light"} `}
          style={{ textTransform: "capitalize" }}
        >
          {data.name}
        </td>

        <td
          className={` text-center ${i % 2 == 1 && "bg-light"} `}
          style={{ textTransform: "capitalize" }}
        >
          {data.vidhanshabha}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {data.email}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {data?.address}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {__formatDate(data.createdAt)}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          {data.status ? (
            <span
              onClick={() => __handleUpdateStatus(data._id, false)}
              className="bx bx-toggle-right text-success fs-3"
            ></span>
          ) : (
            <span
              onClick={() => __handleUpdateStatus(data._id, true)}
              className="bx bx-toggle-left text-secondary fs-3"
            ></span>
          )}
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          <span
            onClick={() => {
              toggle1()
            }}
            className="btn btn-sm btn-success"
          >
            Plan
          </span>
        </td>
        <td className={` text-center ${i % 2 == 1 && "bg-light"} `}>
          <ul className="list-unstyled hstack gap-1 mb-0 justify-content-center">
            <li>
              <a
                className="btn btn-sm btn-soft-info"
                id="edittooltip-10"
                href="#"
                onClick={toggle2}
              >
                <i className="mdi mdi-pencil-outline"></i>
              </a>
            </li>
            <li>
              <a
                className="btn btn-sm btn-soft-danger"
                id="deletetooltip-10"
                href="#"
                onClick={toggle3}
              >
                <i className="mdi mdi-delete-outline"></i>
              </a>
            </li>
          </ul>
        </td>
      </tr>
    </>
  )
}

export default ListData
