import React, { useState } from "react"
import DataContext from "./dataContext"
import axios from "axios"

const DataState = props => {
  const Host = "http://localhost:8000"
  const [user, setUser] = useState(true)

  //  info :Users Api

  const login = async data => {
    var result
    await axios
      .post(`${Host}/user/login`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("authUser", response.data.authtoken)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const signup = async data => {
    var result
    await axios
      .post(`${Host}/user/signup`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("email", data.email)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const EmailVerification = async data => {
    var result
    await axios
      .post(`${Host}/user/verify-email`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          localStorage.setItem("authUser", response.data.authtoken)
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const ReSendOtp = async data => {
    var result
    await axios
      .post(`${Host}/user/resend-otp`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const ForgetPassword = async data => {
    var result
    await axios
      .post(`${Host}/user/forgetpassword`, data)
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }
  const ResetPassword = async data => {
    var result
    await axios
      .post(`${Host}/user/resetpassword`, data, {
        headers: {
          Authorization: new URLSearchParams(window.location.search).get(
            "token"
          ),
        },
      })
      .then(response => {
        response
        console.log("result", response)
        if (response.status === 200) {
          result = true
        } else {
          result = false
        }
      })
      .catch(error => {
        console.log(error)
        result = false
      })
    return result
  }

  return (
    <DataContext.Provider
      value={{
        user,
        setUser,
        login,
        signup,
        EmailVerification,
        ReSendOtp,
        ForgetPassword,
        ResetPassword,
      }}
    >
      {props.children}
    </DataContext.Provider>
  )
}

export default DataState
