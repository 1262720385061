import React, { Fragment, useEffect, useState } from "react"
import { HashLoader } from "react-spinners"

import { Col, Row, Card, CardBody, Table, CardImg, Button } from "reactstrap"
import ListCom from "./ListCom"
import { __generateRandomString } from "constants/constantfun"
import noImage from "../../../assets/images/no_image.png"
import {
  __makeGetVoterListAlphaGetRequest,
  __makeGetVoterListGetRequest,
} from "constants/apis"
import ReactToPrint from "react-to-print"

const Alpha = () => {
  const [paginantion, setpaginantion] = useState({ limit: 2000, offset: 0 })
  const { limit, offset } = paginantion

  const [companionList, setcompanionList] = useState()
  const [count, setCount] = useState()

  const updatePage = data => setpaginantion(prv => ({ ...prv, ...data }))
  const componentRef = React.useRef()
  const __handleGetData = (o, l) => {
    setcompanionList()
    __makeGetVoterListAlphaGetRequest(o, l)
      .then(res => {
        console.log(res)
        if (res.response.response_code === "200") {
          setcompanionList(res?.data.list)
          setCount(res?.data.count)
        } else {
          setcompanionList([])
        }
      })
      .catch(error => {
        console.error(error)
        setcompanionList([])
      })
  }

  useEffect(() => {
    __handleGetData(offset, limit)
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className=" justify-content-end d-flex h4 pe-5 pt-3 px-5">
            Total: {count}
          </div>

          {companionList ? (
            companionList.length > 0 ? (
              <>
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="my-3 justify-content-end d-flex ">
                        <ReactToPrint
                          trigger={() => (
                            <button className="btn btn-success  mx-2 ">
                              Download
                            </button>
                          )}
                          content={() => componentRef.current}
                        />
                        <Button
                          className="btn btn-primary mx-2 px-4"
                          onClick={() => {
                            console.log(offset > 0)
                            if (offset > 0) {
                              updatePage({ offset: offset - limit })
                              __handleGetData(offset - limit, limit)
                            }
                          }}
                        >
                          Prv
                        </Button>
                        <Button
                          className="btn btn-primary mx-2 px-4"
                          onClick={() => {
                            updatePage({ offset: offset + limit })
                            __handleGetData(offset + limit, limit)
                          }}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                    <Fragment>
                      <div
                        className="table-responsive react-table"
                        ref={componentRef}
                      >
                        <Table
                          bordered
                          hover
                          className={"custom-header-css mt-3"}
                        >
                          <thead className="table-light table-nowrap">
                            <tr>
                              <th className="text-center">#</th>
                              <th className="text-center">
                                Voter Name / Family Name{" "}
                              </th>
                              <th className="text-center">Age/Gender</th>
                              <th className="text-center">House No.</th>
                            </tr>
                          </thead>

                          <tbody>
                            {companionList.map((data, i) => (
                              <tr className="bg-danger" key={i + 1}>
                                <td
                                  className={` text-center ${
                                    i % 2 == 1 && "bg-light"
                                  } `}
                                >
                                  {i + 1 + offset}
                                </td>

                                <td
                                  className={` text-center ${
                                    i % 2 == 1 && "bg-light"
                                  } `}
                                >
                                  {data.name_hin} /{data.family_hin}
                                </td>
                                <td
                                  className={` text-center ${
                                    i % 2 == 1 && "bg-light"
                                  } `}
                                >
                                  {data.sex} /{data.age}
                                </td>
                                <td
                                  className={` text-center ${
                                    i % 2 == 1 && "bg-light"
                                  } `}
                                >
                                  {data.house_no}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </Fragment>

                    <Row>
                      <Col className="my-3 justify-content-end d-flex">
                        <Button
                          className="btn btn-primary mx-2 px-4"
                          onClick={() => {
                            console.log(offset > 0)
                            offset > 0 && updatePage({ offset: offset - limit })
                          }}
                        >
                          Prv
                        </Button>
                        <Button
                          className="btn btn-primary mx-2 px-4"
                          onClick={() => {
                            updatePage({ offset: offset + limit })
                          }}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            ) : (
              <>{NoDataFound()}</>
            )
          ) : (
            <>{Loader()}</>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )

  function NoDataFound() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <CardImg
            className="img-fluid"
            src={noImage}
            alt=""
            style={{
              maxHeight: "300px",
              maxWidth: "100%",
              width: "auto",
            }}
          />
        </Row>
      </>
    )
  }
  function Loader() {
    return (
      <>
        <Row
          className=" d-flex justify-content-center  align-items-center "
          style={{ height: "60vh" }}
        >
          <HashLoader color="#36d7b7" />
        </Row>
      </>
    )
  }
}

export default Alpha
